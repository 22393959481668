import { useState } from "react";
import { Button } from "../../../../shared/Button/Button";
import { TextInput } from "../../../ui/TextInput/TextInput";
import { DialogActions } from "./Dialog";
import { FileInput } from "../../../ui/FileInput/FileInput";
import { useTranslation } from "@dws/dws-profile";
export function InsertImageUploadedDialogBody({ onClick, }) {
    const { t } = useTranslation();
    const [src, setSrc] = useState("");
    const [altText, setAltText] = useState("");
    const loadImage = (files) => {
        const reader = new FileReader();
        reader.onload = function () {
            if (typeof reader.result === "string") {
                setSrc(reader.result);
            }
            return "";
        };
        if (files !== null) {
            reader.readAsDataURL(files[0]);
        }
    };
    return (<>
      <FileInput label={t("lexical.image_upload")} onChange={loadImage} accept="image/*" data-test-id="image-modal-file-upload"/>
      <TextInput label={t("lexical.alt_text")} placeholder="Descriptive alternative text" onChange={setAltText} value={altText} data-test-id="image-modal-alt-text-input"/>
      <DialogActions>
        <Button data-test-id="image-modal-file-upload-btn" onClick={() => onClick({ altText, src })}>
          {t("lexical.confirm")}
        </Button>
      </DialogActions>
    </>);
}
