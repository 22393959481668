import { useState } from "react";
import { OpenSHOButton } from "./presentation/components/OpenSHOButton/OpenSHOButton";
import { createPortal } from "react-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { HttpClientProvider } from "./contexts/httpClientContext/httpClientContext";
import { FetchHTTPClient } from "./modules/shared/httpClient/impl/fetchHttpClient";
import "./app.scss";
import { InformationProvider } from "./InformationProvider";
export function App(props) {
    var _a, _b, _c;
    const username = ((_a = props.authenticationData) === null || _a === void 0 ? void 0 : _a.account.name) || "Unknown";
    const accessToken = ((_b = props.authenticationData) === null || _b === void 0 ? void 0 : _b.accessToken) || "Unknown";
    const email = ((_c = props.authenticationData) === null || _c === void 0 ? void 0 : _c.account.username) || "Unknown";
    const [shoOpened, setShoOpened] = useState(false);
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });
    const httpClient = new FetchHTTPClient("");
    httpClient.setAuthenticationHeader(`Bearer ${accessToken}`);
    if (!shoOpened) {
        return (<div className="open-sho-button-wrapper">
        <OpenSHOButton onClick={() => setShoOpened(true)}/>
      </div>);
    }
    const userData = {
        username: username,
        accessToken: accessToken,
        email: email,
        profilePicture: "",
    };
    return createPortal(<HttpClientProvider httpClient={httpClient}>
      <QueryClientProvider client={queryClient}>
        <InformationProvider setShoOpened={setShoOpened} shoOpened={shoOpened} userData={userData}/>
      </QueryClientProvider>
    </HttpClientProvider>, document.querySelector("div.dws-shift-handover-notes"));
}
