import { canDropImage } from "./canDropImage";
import { getImageNodeInSelection } from "./getImageNodeInSelection";
export function onDragover(event) {
    const node = getImageNodeInSelection();
    if (!node) {
        return false;
    }
    if (!canDropImage(event)) {
        event.preventDefault();
    }
    return true;
}
