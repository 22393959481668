import { Button } from "../shared/Button/Button";
import "./PIDashboardButton.scss";
export function PIDashboardButton({ link }) {
    if (!link)
        return null;
    function onClick() {
        window.open(link, "_blank");
    }
    return (<Button className="pi_dashboard_button" onClick={onClick}>
      PI Dashboard
    </Button>);
}
