import { useRef } from "react";
import { useSuspenseImage } from "../../../../../hooks/layout/useSuspenseImage";
import { config } from "@dws/dws-auth";
export function LazyImage({ altText, className, imageRef, src, width, height, maxWidth, fallbackSrcsets, }) {
    const fallbackSrcsetsRef = useRef(fallbackSrcsets);
    const useAuthentication = src.includes(config.IAL_UPLOADS_API);
    const res = useSuspenseImage(src, { useAuthentication });
    function onError() {
        var _a;
        if (imageRef.current && ((_a = fallbackSrcsetsRef.current) === null || _a === void 0 ? void 0 : _a.length)) {
            imageRef.current.src = fallbackSrcsetsRef.current[0];
        }
        fallbackSrcsetsRef.current = fallbackSrcsets === null || fallbackSrcsets === void 0 ? void 0 : fallbackSrcsets.slice(1);
    }
    return (<picture>
      {fallbackSrcsets === null || fallbackSrcsets === void 0 ? void 0 : fallbackSrcsets.map((srcset) => (<source key={srcset} srcSet={srcset} type="image/webp"/>))}
      <img className={className} src={res} alt={altText} ref={imageRef} style={{
            height,
            maxWidth,
            width,
        }} draggable="false" onError={onError}/>
    </picture>);
}
