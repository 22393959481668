import { createContext, useContext } from "react";
const httpClientContext = createContext(null);
export function HttpClientProvider({ httpClient, children }) {
    return (<httpClientContext.Provider value={{ httpClient }}>
      {children}
    </httpClientContext.Provider>);
}
export function useHttpClient() {
    const context = useContext(httpClientContext);
    if (!context) {
        throw new Error("useHttpClient must be used within a HttpClientProvider");
    }
    return context.httpClient;
}
