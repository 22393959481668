import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { dropDownPadding } from "./DropDownItem";
import { DropDownItems } from "./DropDownItems";
import "./Dropdown.scss";
export function DropDown({ disabled = false, buttonLabel, buttonAriaLabel, buttonClassName, buttonIconClassName, children, stopCloseOnClickSelf, }) {
    const dropDownRef = useRef(null);
    const buttonRef = useRef(null);
    const [showDropDown, setShowDropDown] = useState(false);
    const handleClose = () => {
        setShowDropDown(false);
        buttonRef === null || buttonRef === void 0 ? void 0 : buttonRef.current.focus();
    };
    useEffect(() => {
        const button = buttonRef.current;
        const dropDown = dropDownRef.current;
        if (showDropDown && button !== null && dropDown !== null) {
            const { top, left } = button.getBoundingClientRect();
            dropDown.style.top = `${top + button.offsetHeight + dropDownPadding}px`;
            dropDown.style.left = `${Math.min(left, window.innerWidth - dropDown.offsetWidth - 20)}px`;
        }
    }, [dropDownRef, buttonRef, showDropDown]);
    useEffect(() => {
        const button = buttonRef.current;
        if (!showDropDown || button !== null)
            return;
        const handle = (event) => {
            var _a;
            const target = event.target;
            if (stopCloseOnClickSelf) {
                if ((_a = dropDownRef.current) === null || _a === void 0 ? void 0 : _a.contains(target))
                    return;
            }
            if (!button.contains(target)) {
                setShowDropDown(false);
            }
        };
        document.addEventListener("click", handle);
        return () => document.removeEventListener("click", handle);
    }, [dropDownRef, buttonRef, showDropDown, stopCloseOnClickSelf]);
    useEffect(() => {
        const handleButtonPositionUpdate = () => {
            if (showDropDown) {
                const button = buttonRef.current;
                const dropDown = dropDownRef.current;
                if (button !== null && dropDown !== null) {
                    const { top } = button.getBoundingClientRect();
                    const newPosition = top + button.offsetHeight + dropDownPadding;
                    if (newPosition !== dropDown.getBoundingClientRect().top) {
                        dropDown.style.top = `${newPosition}px`;
                    }
                }
            }
        };
        document.addEventListener("scroll", handleButtonPositionUpdate);
        return () => {
            document.removeEventListener("scroll", handleButtonPositionUpdate);
        };
    }, [buttonRef, dropDownRef, showDropDown]);
    return (<>
      <button type="button" disabled={disabled} aria-label={buttonAriaLabel || buttonLabel} className={buttonClassName} onClick={() => setShowDropDown(!showDropDown)} ref={buttonRef}>
        {buttonIconClassName && <span className={buttonIconClassName}/>}
        {buttonLabel && (<span className="text dropdown-button-text">{buttonLabel}</span>)}
      </button>

      {showDropDown &&
            createPortal(<DropDownItems dropDownRef={dropDownRef} onClose={handleClose}>
            {children}
          </DropDownItems>, document.querySelector("div.dws-shift-handover-notes"))}
    </>);
}
