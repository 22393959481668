import { useTranslation } from "@dws/dws-profile";
import { FORMAT_TEXT_COMMAND } from "lexical";
export function UnderlineButton({ activeEditor, isEditable, isUnderline, }) {
    const { t } = useTranslation();
    function onClick() {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline");
    }
    return (<button disabled={!isEditable} onClick={onClick} data-testid="underline-button" className={"toolbar-item spaced " + (isUnderline ? "active" : "")} title={t("lexical.underline")} type="button" aria-label={`Format text to underlined. Shortcut: ${"Ctrl+U"}`}>
      <i className="format underline"/>
    </button>);
}
