import useLexicalEditable from "@lexical/react/useLexicalEditable";
import * as React from "react";
import { createPortal } from "react-dom";
import { TableCellActionMenuContainer } from "./TableCellActionMenuContainer";
export function TableActionMenuPlugin({ anchorElem = document.body, cellMerge = false, }) {
    const isEditable = useLexicalEditable();
    if (!anchorElem)
        return null;
    return createPortal(isEditable ? (<TableCellActionMenuContainer anchorElem={anchorElem} cellMerge={cellMerge}/>) : null, anchorElem);
}
