import { BoldButton } from "./BoldButton";
import { ItalicButton } from "./ItalicButton";
import { RedoButton } from "./RedoButton";
import { UnderlineButton } from "./UnderlineButton";
import { UndoButton } from "./UndoButton";
export const ToolbarButton = {
    Bold: BoldButton,
    Italic: ItalicButton,
    Redo: RedoButton,
    Undo: UndoButton,
    Underline: UnderlineButton,
};
