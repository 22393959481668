export function toHex(value) {
    if (!value.startsWith("#")) {
        const ctx = document.createElement("canvas").getContext("2d");
        if (!ctx) {
            throw new Error("2d context not supported or canvas already initialized");
        }
        ctx.fillStyle = value;
        return ctx.fillStyle;
    }
    else if (value.length === 4 || value.length === 5) {
        value = value
            .split("")
            .map((v, i) => (i ? v + v : "#"))
            .join("");
        return value;
    }
    else if (value.length === 7 || value.length === 9) {
        return value;
    }
    return "#000000";
}
