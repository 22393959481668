import { COMMAND_PRIORITY_CRITICAL, SELECTION_CHANGE_COMMAND, } from "lexical";
export function selectionChangeEffect(editor, setActiveEditor, $updateToolbar) {
    return () => {
        return editor.registerCommand(SELECTION_CHANGE_COMMAND, (_payload, newEditor) => {
            $updateToolbar();
            setActiveEditor(newEditor);
            return false;
        }, COMMAND_PRIORITY_CRITICAL);
    };
}
