import { useTranslation } from "@dws/dws-profile";
import { REDO_COMMAND } from "lexical";
export function RedoButton({ activeEditor, canRedo, isEditable, }) {
    const { t } = useTranslation();
    function onClick() {
        activeEditor.dispatchCommand(REDO_COMMAND, undefined);
    }
    return (<button disabled={!canRedo || !isEditable} onClick={onClick} title={t("lexical.redo")} data-testid="redo-button" type="button" className="toolbar-item" aria-label="Redo">
      <i className="format redo"/>
    </button>);
}
