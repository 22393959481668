import "./ShiftTitle.scss";
import { useTranslation } from "@dws/dws-profile";
function shiftLabel(shift) {
    const position = shift.positionIndexFromCurrent;
    if (position === 0) {
        return "current";
    }
    else if (position === -1) {
        return "previous";
    }
    else if (position === 1) {
        return "next";
    }
    else if (position <= -2) {
        return "past";
    }
    else if (position >= 2) {
        return "future";
    }
    return "";
}
;
export function ShiftTitle({ shift }) {
    const { t } = useTranslation();
    const title = shiftLabel(shift);
    if (!title)
        return null;
    return (<div className="shift-title">
      {t(`sho.${title}`)}
    </div>);
}
