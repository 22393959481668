import { TableNode, TableRowNode, TableCellNode } from "@lexical/table";
import { ListNode, ListItemNode } from "@lexical/list";
import { TabNode } from "lexical";
import { QuoteNode, HeadingNode } from "@lexical/rich-text";
import { HorizontalRuleNode } from "@lexical/react/LexicalHorizontalRuleNode";
import { ImageNode } from "./nodes/ImageNode/ImageNode";
export const EditorNodes = [
    TableNode,
    TableRowNode,
    TableCellNode,
    TabNode,
    ListNode,
    ListItemNode,
    QuoteNode,
    HeadingNode,
    HorizontalRuleNode,
    ImageNode,
];
