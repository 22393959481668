import { $isTableCellNode } from "@lexical/table";
import { $getSelection, $isRangeSelection, DEPRECATED_$getNodeTriplet, DEPRECATED_$isGridSelection, } from "lexical";
export function currentCellBackgroundColor(editor) {
    return editor.getEditorState().read(() => {
        const selection = $getSelection();
        if ($isRangeSelection(selection) ||
            DEPRECATED_$isGridSelection(selection)) {
            const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor);
            if ($isTableCellNode(cell)) {
                return cell.getBackgroundColor();
            }
        }
        return null;
    });
}
