import { Button } from "../shared/Button/Button";
import { useTranslation } from "@dws/dws-profile";
export function OpenSHOButton(props) {
    const { t } = useTranslation();
    let label = "reports.shift_handover_notes";
    if (props.error)
        label = "general.error";
    else if (props.loading)
        label = "general.loading";
    return (<div className="open-sho-button-wrapper">
      <Button data-testid="sho-button" {...props}>
        {t(label)}
      </Button>
    </div>);
}
