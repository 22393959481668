import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useCallback, useEffect, useState } from "react";
import { Divider } from "./Divider";
import { ToolbarButton } from "./Buttons";
import { ToolbarDropdown } from "./Dropdowns";
import { toolbarCallbacks } from "./callbacks";
import { toolbarEffects } from "./effects";
import "./Toolbar.scss";
import "./BlockControls.scss";
export function ToolbarPlugin() {
    const [editor] = useLexicalComposerContext();
    const [activeEditor, setActiveEditor] = useState(editor);
    const [blockType, setBlockType] = useState("paragraph");
    const [rootType, setRootType] = useState("root");
    const [fontSize, setFontSize] = useState("15px");
    const [fontColor, setFontColor] = useState("#fff");
    const [bgColor, setBgColor] = useState("#000");
    const [fontFamily, setFontFamily] = useState("Arial");
    const [isLink, setIsLink] = useState(false);
    const [isBold, setIsBold] = useState(false);
    const [isItalic, setIsItalic] = useState(false);
    const [isUnderline, setIsUnderline] = useState(false);
    const [isStrikethrough, setIsStrikethrough] = useState(false);
    const [isSubscript, setIsSubscript] = useState(false);
    const [isSuperscript, setIsSuperscript] = useState(false);
    const [canUndo, setCanUndo] = useState(false);
    const [canRedo, setCanRedo] = useState(false);
    const [isRTL, setIsRTL] = useState(false);
    const [isEditable, setIsEditable] = useState(() => editor.isEditable());
    const $updateToolbar = useCallback(toolbarCallbacks.updateToolbar(activeEditor, setIsBold, setIsItalic, setIsUnderline, setIsStrikethrough, setIsSubscript, setIsSuperscript, setIsRTL, setIsLink, setRootType, setBlockType, setFontSize, setFontColor, setBgColor, setFontFamily), [activeEditor]);
    useEffect(toolbarEffects.selectionChange(editor, setActiveEditor, $updateToolbar), [editor, $updateToolbar]);
    useEffect(toolbarEffects.mergeRegister(editor, activeEditor, $updateToolbar, setIsEditable, setCanUndo, setCanRedo), [$updateToolbar, activeEditor, editor]);
    useEffect(() => toolbarEffects.keyModifier(activeEditor), [activeEditor, isLink]);
    const applyStyleText = useCallback(toolbarCallbacks.applyStyleText(activeEditor), [activeEditor]);
    const clearFormatting = useCallback(toolbarCallbacks.clearFormatting(activeEditor), [activeEditor]);
    const onFontColorSelect = useCallback((value) => {
        applyStyleText({ color: value });
    }, [applyStyleText]);
    const onBgColorSelect = useCallback((value) => {
        applyStyleText({ "background-color": value });
    }, [applyStyleText]);
    return (<div className="toolbar">
      {/* <ToolbarButton.Undo
          activeEditor={activeEditor}
          canUndo={canUndo}
          isEditable={isEditable}
        />
        <ToolbarButton.Redo
          activeEditor={activeEditor}
          canRedo={canRedo}
          isEditable={isEditable}
        />
        <Divider /> */}
      <ToolbarDropdown.BlockFormat disabled={!isEditable} blockType={blockType} rootType={rootType} editor={editor}/>
      <Divider />
      <ToolbarDropdown.Font disabled={!isEditable} style={"font-family"} value={fontFamily} editor={editor}/>
      <ToolbarDropdown.Font disabled={!isEditable} style={"font-size"} value={fontSize} editor={editor}/>
      <Divider />
      <ToolbarButton.Bold activeEditor={activeEditor} isEditable={isEditable} isBold={isBold}/>
      <ToolbarButton.Italic activeEditor={activeEditor} isEditable={isEditable} isItalic={isItalic}/>
      <ToolbarButton.Underline activeEditor={activeEditor} isEditable={isEditable} isUnderline={isUnderline}/>
      <ToolbarDropdown.ColorPicker disabled={!isEditable} buttonClassName="toolbar-item color-picker" buttonAriaLabel="Formatting text color" buttonIconClassName="icon font-color" color={fontColor} onChange={onFontColorSelect} title="text color"/>
      <ToolbarDropdown.ColorPicker disabled={!isEditable} buttonClassName="toolbar-item color-picker" buttonAriaLabel="Formatting background color" buttonIconClassName="icon bg-color" color={bgColor} onChange={onBgColorSelect} title="bg color"/>
      <ToolbarDropdown.TextStyle activeEditor={activeEditor} isEditable={isEditable} clearFormatting={clearFormatting} isStrikethrough={isStrikethrough} isSubscript={isSubscript} isSuperscript={isSuperscript}/>
      <Divider />
      <ToolbarDropdown.Align activeEditor={activeEditor} isEditable={isEditable} isRTL={isRTL}/>
      <ToolbarDropdown.SpecialNode activeEditor={activeEditor} isEditable={isEditable}/>
    </div>);
}
