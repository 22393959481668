import * as React from "react";
import { useEffect, useRef } from "react";
export const DropDownContext = React.createContext(null);
export const dropDownPadding = 4;
export function DropDownItem({ children, className, onClick, title, }) {
    const ref = useRef(null);
    const dropDownContext = React.useContext(DropDownContext);
    if (dropDownContext === null) {
        throw new Error("DropDownItem must be used within a DropDown");
    }
    const { registerItem } = dropDownContext;
    useEffect(() => {
        if (ref === null || ref === void 0 ? void 0 : ref.current)
            registerItem(ref);
    }, [ref, registerItem]);
    return (<button className={className} onClick={onClick} ref={ref} title={title} type="button">
      {children}
    </button>);
}
