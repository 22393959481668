import { $getSelection, $isNodeSelection } from "lexical";
import { $isImageNode } from "../../nodes/ImageNode/ImageNode";
export function getImageNodeInSelection() {
    const selection = $getSelection();
    if (!$isNodeSelection(selection)) {
        return null;
    }
    const nodes = selection.getNodes();
    const node = nodes[0];
    return $isImageNode(node) ? node : null;
}
