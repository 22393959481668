import { forwardRef, useCallback, useEffect, useLayoutEffect, useRef, useState, } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import * as Y from "yjs";
import { AutoFocusPlugin, CheckListPlugin, ClearEditorPlugin, CollaborationPlugin, ContentEditable, HistoryPlugin, HorizontalRulePlugin, ImagesPlugin, LexicalErrorBoundary, ListPlugin, RichTextPlugin, TabIndentationPlugin, TablePlugin, ToolbarPlugin, TableActionMenuPlugin, } from "./plugins/LexicalPlugins";
import { EditorTheme } from "./EditorTheme";
import { EditorNodes } from "./lexicalNodes";
import "./Editor.scss";
import "./EditorTable.scss";
import "./EditorTypography.scss";
import "./Icons.scss";
import { useTranslation } from "@dws/dws-profile";
export function Editor({ namespace, providerFactory, username }) {
    const [container, target] = useSyncElementsPosition();
    const [timer, setTimer] = useState(false);
    useEffect(() => {
        setTimeout(() => setTimer(true), 2000);
    }, []);
    const [floatingAnchorElem, setFloatingAnchorElem] = useState(null);
    const onRef = (_floatingAnchorElem) => {
        setFloatingAnchorElem(_floatingAnchorElem);
    };
    const provider = useCallback((id, yjsDocMap) => {
        let doc = yjsDocMap.get(id);
        if (!doc) {
            doc = new Y.Doc();
            yjsDocMap.set(id, doc);
        }
        else {
            doc.load();
        }
        return providerFactory(id, yjsDocMap);
    }, [namespace]);
    if (!timer)
        return <LoadingScaffold />;
    const EditorConfig = {
        namespace,
        theme: EditorTheme,
        nodes: EditorNodes,
        onError: console.error,
        editorState: null,
    };
    return (<LexicalComposer initialConfig={EditorConfig}>
      <AutoFocusPlugin />
      <ClearEditorPlugin />
      <ListPlugin />
      <HistoryPlugin />
      <CheckListPlugin />
      <TablePlugin />
      <HorizontalRulePlugin />
      <TabIndentationPlugin />
      <ImagesPlugin />
      <ToolbarPlugin />
      <TableActionMenuPlugin anchorElem={floatingAnchorElem} cellMerge={true}/>
      <CollaborationPlugin id={namespace} providerFactory={provider} shouldBootstrap={false} username={username}/>
      <RichTextPlugin placeholder={<Placeholder ref={target}/>} ErrorBoundary={LexicalErrorBoundary} contentEditable={<div className="editor-scroller" ref={container}>
            <div className="editor" ref={onRef}>
              <ContentEditable />
            </div>
          </div>}/>
    </LexicalComposer>);
}
function LoadingScaffold() {
    const messages = [
        "Searching for previous notes...",
        "Loading editor",
        "Setting up live mode",
    ];
    const intervals = [500, 500, 500];
    const [message, setMessage] = useState(messages[0]);
    useEffect(() => {
        let i = 0;
        const interval = setInterval(() => {
            setMessage(messages[i]);
            i = (i + 1) % messages.length;
        }, intervals[i]);
        return () => clearInterval(interval);
    }, []);
    return <div className="editor-loading">{message}</div>;
}
const Placeholder = forwardRef((_, ref) => {
    const { t } = useTranslation();
    return (<div className="placeholder" ref={ref}>
      {t("sho.placeholder")}
    </div>);
});
export function useSyncElementsPosition() {
    const component1Ref = useRef(null);
    const component2Ref = useRef(null);
    useLayoutEffect(() => {
        if (!component1Ref.current || !component2Ref.current)
            return;
        const observer = new ResizeObserver(() => {
            const component1 = component1Ref.current;
            const component2 = component2Ref.current;
            component2.style.top = `${component1.offsetTop}px`;
            component2.style.left = `${component1.offsetLeft}px`;
        });
        observer.observe(component1Ref.current);
        return () => observer.unobserve(component1Ref.current);
    }, [component1Ref.current, component2Ref.current]);
    return [component1Ref, component2Ref];
}
