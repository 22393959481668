import { $createParagraphNode, $getSelection, $isParagraphNode, DEPRECATED_$isGridCellNode, DEPRECATED_$isGridSelection, } from "lexical";
import { $cellContainsEmptyParagraph } from "../$cellContainsEmptyParagraph";
import { $selectLastDescendant } from "../$selectLastDescendant";
import { computeSelectionCount } from "../computeSelectionCount";
export function mergeTableCellsAtSelectionImpl(editor, onClose) {
    return () => editor.update(() => {
        const selection = $getSelection();
        if (DEPRECATED_$isGridSelection(selection)) {
            const { columns, rows } = computeSelectionCount(selection);
            const nodes = selection.getNodes();
            let firstCell = null;
            for (let i = 0; i < nodes.length; i++) {
                const node = nodes[i];
                if (DEPRECATED_$isGridCellNode(node)) {
                    if (firstCell === null) {
                        node.setColSpan(columns).setRowSpan(rows);
                        firstCell = node;
                        const isEmpty = $cellContainsEmptyParagraph(node);
                        let firstChild;
                        if (isEmpty &&
                            $isParagraphNode((firstChild = node.getFirstChild()))) {
                            firstChild.remove();
                        }
                    }
                    else if (DEPRECATED_$isGridCellNode(firstCell)) {
                        const isEmpty = $cellContainsEmptyParagraph(node);
                        if (!isEmpty) {
                            firstCell.append(...node.getChildren());
                        }
                        node.remove();
                    }
                }
            }
            if (firstCell !== null) {
                if (firstCell.getChildrenSize() === 0) {
                    firstCell.append($createParagraphNode());
                }
                $selectLastDescendant(firstCell);
            }
            onClose();
        }
    });
}
