import { $getTableNodeFromLexicalNodeOrThrow, $getTableRowIndexFromTableCellNode, $isTableRowNode, $isTableCellNode, TableCellHeaderStates, } from "@lexical/table";
export function toggleTableRowIsHeaderCallback(editor, tableCellNode, clearTableSelection, onClose) {
    return () => editor.update(() => {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
        const tableRowIndex = $getTableRowIndexFromTableCellNode(tableCellNode);
        const tableRows = tableNode.getChildren();
        if (tableRowIndex >= tableRows.length || tableRowIndex < 0) {
            throw new Error("Expected table cell to be inside of table row.");
        }
        const tableRow = tableRows[tableRowIndex];
        if (!$isTableRowNode(tableRow)) {
            throw new Error("Expected table row");
        }
        tableRow.getChildren().forEach((tableCell) => {
            if (!$isTableCellNode(tableCell)) {
                throw new Error("Expected table cell");
            }
            tableCell.toggleHeaderStyle(TableCellHeaderStates.ROW);
        });
        clearTableSelection();
        onClose();
    });
}
