import { FORMAT_ELEMENT_COMMAND, OUTDENT_CONTENT_COMMAND, INDENT_CONTENT_COMMAND, } from "lexical";
import { DropDown } from "../../../ui/DropDown/Dropdown";
import { DropDownItem } from "../../../ui/DropDown/DropDownItem";
import { Divider } from "../Divider";
import { useTranslation } from "@dws/dws-profile";
export function AlignDropDown({ activeEditor, isEditable, isRTL, }) {
    const { t } = useTranslation();
    return (<DropDown disabled={!isEditable} buttonLabel={t("lexical.alignment")} buttonIconClassName="icon left-align" buttonClassName="toolbar-item spaced alignment" buttonAriaLabel="Formatting options for text alignment">
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left");
        }} className="item">
        <i className="icon left-align"/>
        <span className="text">{t("lexical.align_left")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center");
        }} className="item">
        <i className="icon center-align"/>
        <span className="text">{t("lexical.align_center")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right");
        }} className="item">
        <i className="icon right-align"/>
        <span className="text">{t("lexical.align_right")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify");
        }} className="item">
        <i className="icon justify-align"/>
        <span className="text">{t("lexical.align_justify")}</span>
      </DropDownItem>
      <Divider />
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(OUTDENT_CONTENT_COMMAND, undefined);
        }} className="item">
        <i className={"icon " + (isRTL ? "indent" : "outdent")}/>
        <span className="text">{t("lexical.outdent")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(INDENT_CONTENT_COMMAND, undefined);
        }} className="item">
        <i className={"icon " + (isRTL ? "outdent" : "indent")}/>
        <span className="text">{t("lexical.indent")}</span>
      </DropDownItem>
    </DropDown>);
}
