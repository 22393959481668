import { DropdownColorPicker } from "../../../ui/DropDownColorPicker/DropDownColorPicker";
import { AlignDropDown } from "./AlignDropDown";
import { BlockFormatDropDown } from "./BlockFormatDropDown";
import { FontDropDown } from "./FontDropDown";
import { SpecialNodeDropdown } from "./SpecialNodeDropdown";
import { TextStyleDropDown } from "./TextStyleDropdown";
export const ToolbarDropdown = {
    ColorPicker: DropdownColorPicker,
    Align: AlignDropDown,
    Font: FontDropDown,
    TextStyle: TextStyleDropDown,
    BlockFormat: BlockFormatDropDown,
    SpecialNode: SpecialNodeDropdown,
};
