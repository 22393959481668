import { $getSelection, $isRangeSelection, DEPRECATED_$getNodeTriplet, DEPRECATED_$isGridSelection, } from "lexical";
export function $canUnmerge() {
    const selection = $getSelection();
    if (($isRangeSelection(selection) && !selection.isCollapsed()) ||
        (DEPRECATED_$isGridSelection(selection) &&
            !selection.anchor.is(selection.focus)) ||
        (!$isRangeSelection(selection) && !DEPRECATED_$isGridSelection(selection))) {
        return false;
    }
    const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor);
    return cell.__colSpan > 1 || cell.__rowSpan > 1;
}
