import { hex2rgb } from "./functions/hex2RGB";
import { hsv2rgb } from "./functions/hsv2rgb";
import { rgb2hex } from "./functions/rgb2hex";
import { rgb2hsv } from "./functions/rgb2hsv";
import { toHex } from "./functions/toHex";
export function transformColor(format, color) {
    let hex = toHex("#121212");
    let rgb = hex2rgb(hex);
    let hsv = rgb2hsv(rgb);
    if (format === "hex") {
        const value = color;
        hex = toHex(value);
        rgb = hex2rgb(hex);
        hsv = rgb2hsv(rgb);
    }
    else if (format === "rgb") {
        const value = color;
        rgb = value;
        hex = rgb2hex(rgb);
        hsv = rgb2hsv(rgb);
    }
    else if (format === "hsv") {
        const value = color;
        hsv = value;
        rgb = hsv2rgb(hsv);
        hex = rgb2hex(rgb);
    }
    return { hex, hsv, rgb };
}
