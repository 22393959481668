import { useCallback, useMemo, useState } from "react";
import { Modal } from "../../../presentation/components/shared/Modal/Modal";
export function useModal() {
    const [modalContent, setModalContent] = useState(null);
    const onClose = useCallback(() => setModalContent(null), []);
    const modal = useMemo(() => {
        if (!modalContent)
            return null;
        return (<Modal onClose={onClose} title={modalContent.title} closeOnClickOutside={modalContent.closeOnClickOutside}>
        {modalContent.content}
      </Modal>);
    }, [modalContent, onClose]);
    const showModal = useCallback((title, getContent, closeOnClickOutside = false) => {
        setModalContent({
            closeOnClickOutside,
            content: getContent(onClose),
            title,
        });
    }, [onClose]);
    return [modal, showModal];
}
