export function hex2rgb(hex) {
    const rbgArr = (hex
        .replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i, (_, r, g, b) => "#" + r + r + g + g + b + b)
        .substring(1)
        .match(/.{2}/g) || ["ff", "ff", "ff"]).map((x) => parseInt(x, 16));
    return {
        b: rbgArr[2],
        g: rbgArr[1],
        r: rbgArr[0],
    };
}
