import { useEffect } from "react";
export var CustomEventType;
(function (CustomEventType) {
    CustomEventType["BUTTON_CLICKED"] = "BUTTON_CLICKED";
    CustomEventType["CHART_DATA"] = "CHART_DATA";
    CustomEventType["NAVIGATE"] = "NAVIGATE";
    CustomEventType["COUNT_CHANGED"] = "COUNT_CHANGED";
    CustomEventType["LOAD"] = "LOAD";
    CustomEventType["ERROR"] = "ERROR";
    CustomEventType["REQUEST_REFRESH"] = "REQUEST_REFRESH";
    CustomEventType["FILTERS_CHANGED"] = "FILTERS_CHANGED";
})(CustomEventType || (CustomEventType = {}));
document["previousCalls"] || (document["previousCalls"] = {
    [CustomEventType.BUTTON_CLICKED]: null,
    [CustomEventType.CHART_DATA]: null,
    [CustomEventType.NAVIGATE]: null,
    [CustomEventType.COUNT_CHANGED]: null,
    [CustomEventType.LOAD]: null,
    [CustomEventType.ERROR]: null,
    [CustomEventType.REQUEST_REFRESH]: null,
    [CustomEventType.FILTERS_CHANGED]: null,
});
/**
 * @example
 * import { useCustomEventBusListener } from 'hooks/useEventBus';
 *
 * useCustomEventBusListener("BUTTON_CLICKED", (event) => {
 *  console.log(event.payload.buttonId);
 * });
 **/
export function useCustomEventBusListener(eventType, callback) {
    useEffect(() => {
        const eventListener = (event) => {
            callback(event.detail);
        };
        // If the event was dispatched before the listener was added, call the listener immediately
        if (document["previousCalls"][eventType]) {
            callback(document["previousCalls"][eventType]);
        }
        self.addEventListener(eventType, eventListener);
        return () => self.removeEventListener(eventType, eventListener);
    }, [eventType]);
}
export function useEventBusLastCall(eventType) {
    return document["previousCalls"][eventType];
}
/**
 * @example
 * import { dispatchCustomEvent } from 'hooks/useEventBus';
 *
 * export const ButtonComponent: React.FC<ButtonComponentProps> = ({ buttonId }) => {
 *   const handleClick = () => {
 *     dispatchCustomEvent({ type: "BUTTON_CLICKED", payload: { buttonId } });
 *   };
 *
 *   return <button onClick={handleClick}>Click me!</button>;
 * }
 **/
export function dispatchCustomEvent(event) {
    self.dispatchEvent(new CustomEvent(event.type, { detail: event }));
    // stores the event in the browser's event cache, so if the event is dispatched before the listener is added, the listener will still be called
    document["previousCalls"][event.type] = event;
}
export function cleanupPreviousCalls() {
    Object.keys(document["previousCalls"]).forEach((eventType) => {
        document["previousCalls"][eventType] = null;
    });
}
