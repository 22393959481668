import { useEffect, useState } from "react";
import { useShiftHandoverNotesRoom } from "../../../hooks/useShiftHandoverNotesRoom";
import { Avatar } from "../Avatar/Avatar";
import { DateNavigator } from "../DateNavigator/DateNavigator";
import { Editor } from "../Editor/Editor";
import { Information } from "../Information/Information";
import { PeopleInvolved } from "../PeopleInvolved/PeopleInvolved";
import { ShiftTitle } from "../ShiftTitle/ShiftTitle";
import { useMeasure } from "../../../hooks/layout/useMeasure";
import { useTranslation } from "@dws/dws-profile";
import "./Shift.scss";
export function Shift({ side, location, area, subarea, equipment, machine, shift, isSingle, lastUpdate, userData, }) {
    const { t } = useTranslation();
    const className = `${side}-editor`;
    const roomId = [
        location.locationId,
        area.areaId,
        subarea.subareaId,
        equipment.equipmentId,
        machine.machineId,
        shift.start.replace("T", " ").replace("Z", ".000Z"),
        shift.id,
    ].join(`-`);
    const [expandedInformation, setExpandedInformation] = useState(false);
    const [editors, setEditors] = useState([]);
    const [ref, { width }] = useMeasure({ eagerObserver: false });
    useEffect(() => setEditors([]), [roomId]);
    function toggleExpandedInformation() {
        setExpandedInformation((expanded) => !expanded);
    }
    ;
    function onEditorJoined(users) {
        setEditors(users);
    }
    ;
    const providerFactory = useShiftHandoverNotesRoom({
        roomId,
        accessToken: userData.accessToken,
        onEditorJoined,
        user: {
            name: userData.username,
            email: userData.email,
            profilePicture: userData.profilePicture,
        },
    });
    return (<div className={className} data-testid={className} ref={ref}>
      <ShiftTitle shift={shift}/>
      <DateNavigator side={side} isSingle={isSingle}/>
      <section className="editor-info-wrapper">
        <section className="information-expander">
          <h2 className="editor-title">{t("sho.sho_information")}</h2>
        <button className={`toggle-button ${expandedInformation ? "expanded" : ""}`} onClick={toggleExpandedInformation}>
          <span className="icon"></span>
        </button>
        </section>
        <InformationMenu area={area} subarea={subarea} equipment={equipment} editors={editors} expanded={expandedInformation} lastUpdate={lastUpdate} width={width}/>
      </section>
      <div className="editor-wrapper">
        <Editor key={roomId} namespace={roomId} providerFactory={providerFactory} username={userData.username}/>
      </div>
    </div>);
}
function getCountForWidth(width) {
    return Math.round(width / 100);
}
function InformationMenu({ expanded, area, subarea, equipment, lastUpdate, editors, width, }) {
    const { i18n } = useTranslation();
    const language = i18n.language;
    const equipmentName = equipment[`equipmentName${language.toUpperCase()}`] ||
        equipment.equipmentName;
    const departmentName = area[`areaName${language.toUpperCase()}`] || area.areaName;
    const [expandedPeopleInvolved, setExpandedPeopleInvolved] = useState(false);
    if (!expanded)
        return null;
    const maxUsersCollapsed = getCountForWidth(width);
    const filteredUsers = editors.filter((_, i) => expandedPeopleInvolved || i < maxUsersCollapsed);
    function toggleExpandedPeopleInvolved() {
        setExpandedPeopleInvolved((expanded) => !expanded);
    }
    const avatarFactory = (user) => (<Avatar key={user.email} name={user.name} email={user.email} contributedAt={user.contributedAt} profilePicture={user.profilePicture} handoverNoteId={user.handoverNoteId}/>);
    return (<section className="information-wrapper">
      <Information departmentName={departmentName} areaName={subarea.subareaName} equipmentName={equipmentName} lastUpdate={lastUpdate}/>
      <hr />
      <PeopleInvolved expanded={expandedPeopleInvolved} onClickExpand={toggleExpandedPeopleInvolved} showExpandButton={editors.length > maxUsersCollapsed}>
        {filteredUsers.map(avatarFactory)}
      </PeopleInvolved>
      {/* Disabled as the extra information is not defined yet */}
      {/* <ExtraInformation /> */}
    </section>);
}
