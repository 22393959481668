import { Awareness } from "y-protocols/awareness";
import { useCallback } from "react";
import { config } from "@dws/dws-auth";
import { SocketIOProvider } from "y-socket.io";
export function useShiftHandoverNotesRoom({ roomId, accessToken, user, onEditorJoined, }) {
    return useCallback((id, yjsDocMap) => {
        var _a;
        const doc = yjsDocMap.get(id);
        const provider = new SocketIOProvider(config.IAL_SHO_API, roomId, doc, {
            autoConnect: true,
            disableBc: false,
            resyncInterval: 1000,
            awareness: new Awareness(doc),
            auth: {
                token: accessToken,
            },
        }, {
            path: "/shift-handover/socket.io",
        });
        (_a = provider.socket) === null || _a === void 0 ? void 0 : _a.on("editor-joined", onEditorJoined);
        // if the local editor mutates the document, broadcast the changes to other editors,
        // then we send  a handshake with the user information
        doc.on("update", (_, origin) => {
            var _a;
            if (origin === provider)
                return;
            (_a = provider.socket) === null || _a === void 0 ? void 0 : _a.emit("handshake", user);
        });
        return provider;
    }, [roomId]);
}
