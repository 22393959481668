import { $isDecoratorBlockNode } from "@lexical/react/LexicalDecoratorBlockNode";
import { $getNearestBlockElementAncestorOrThrow } from "@lexical/utils";
import { $createParagraphNode, $getSelection, $isRangeSelection, $isTextNode, } from "lexical";
import { $isHeadingNode, $isQuoteNode } from "@lexical/rich-text";
export function clearFormattingCallback(activeEditor) {
    return () => {
        activeEditor.update(() => {
            const selection = $getSelection();
            if (!$isRangeSelection(selection))
                return;
            const anchor = selection.anchor;
            const focus = selection.focus;
            const nodes = selection.getNodes();
            if (anchor.key === focus.key && anchor.offset === focus.offset) {
                return;
            }
            nodes.forEach((node, idx) => {
                // We split the first and last node by the selection
                // So that we don't format unselected text inside those nodes
                if ($isHeadingNode(node) || $isQuoteNode(node)) {
                    return node.replace($createParagraphNode(), true);
                }
                if ($isDecoratorBlockNode(node)) {
                    return node.setFormat("");
                }
                if (!$isTextNode(node))
                    return;
                if (idx === 0 && anchor.offset !== 0) {
                    node = node.splitText(anchor.offset)[1] || node;
                }
                if (idx === nodes.length - 1) {
                    node = node.splitText(focus.offset)[0] || node;
                }
                if (node.__style !== "") {
                    node.setStyle("");
                }
                if (node.__format !== 0) {
                    node.setFormat(0);
                    $getNearestBlockElementAncestorOrThrow(node).setFormat("");
                }
            });
        });
    };
}
