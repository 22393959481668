import * as React from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DropDownContext } from "./DropDownItem";
export function DropDownItems({ children, dropDownRef, onClose, }) {
    const [items, setItems] = useState();
    const [highlightedItem, setHighlightedItem] = useState();
    const registerItem = useCallback((itemRef) => {
        setItems((prev) => (prev ? [...prev, itemRef] : [itemRef]));
    }, [setItems]);
    const handleKeyDown = (event) => {
        if (!items)
            return;
        const key = event.key;
        if (["Escape", "ArrowUp", "ArrowDown", "Tab"].includes(key)) {
            event.preventDefault();
        }
        if (key === "Escape" || key === "Tab") {
            onClose();
        }
        else if (key === "ArrowUp") {
            setHighlightedItem((prev) => {
                if (!prev)
                    return items[0];
                const index = items.indexOf(prev) - 1;
                return items[index === -1 ? items.length - 1 : index];
            });
        }
        else if (key === "ArrowDown") {
            setHighlightedItem((prev) => {
                if (!prev)
                    return items[0];
                return items[items.indexOf(prev) + 1];
            });
        }
    };
    useEffect(() => {
        self.addEventListener("click", onClose);
        return () => self.removeEventListener("click", onClose);
    }, [items]);
    const contextValue = useMemo(() => ({
        registerItem,
    }), [registerItem]);
    useEffect(() => {
        if (items && !highlightedItem) {
            setHighlightedItem(items[0]);
        }
        if (highlightedItem === null || highlightedItem === void 0 ? void 0 : highlightedItem.current) {
            highlightedItem.current.focus();
        }
    }, [items, highlightedItem]);
    return (<DropDownContext.Provider value={contextValue}>
      <div className="dropdown" ref={dropDownRef} onKeyDown={handleKeyDown}>
        {children}
      </div>
    </DropDownContext.Provider>);
}
