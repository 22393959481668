import { format, parseISO } from "date-fns";
import * as locales from "date-fns/locale";
import { useShiftVisualizationContext } from "../../../contexts/ShiftVisualizationContext/useShiftVisualizationContext";
import { useTranslation } from "@dws/dws-profile";
import "./DateNavigator.scss";
export function DateNavigator({ side, isSingle }) {
    const language = useTranslation().i18n.language.split('-')[0];
    const { navigateForward, navigateBackward, config, isLoading } = useShiftVisualizationContext();
    const { shifts } = config;
    const className = `${side}-date-navigator`;
    const showNavigateFoward = isSingle || (!isSingle && side === "right");
    const showNavigateBackward = isSingle || (!isSingle && side === "left");
    const [leftShift, rightShift] = shifts;
    const thisShift = side === "left" ? leftShift : rightShift;
    const parsedDate = parseISO(thisShift.start);
    const formattedDate = format(parsedDate, "do MMMM", {
        locale: locales[language],
    });
    const shiftName = thisShift.name;
    const label = `${formattedDate} - ${shiftName}`;
    return (<div className={className}>
      {showNavigateBackward && (<button className="left-side-navigator" data-testid="left-side-navigator" onClick={navigateBackward} disabled={isLoading}>
          <span className="icon"></span>
        </button>)}
      <span>{label}</span>
      {showNavigateFoward && (<button className="right-side-navigator" data-testid="right-side-navigator" onClick={navigateForward} disabled={isLoading}>
          <span className="icon"></span>
        </button>)}
    </div>);
}
