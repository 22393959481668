import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $deleteTableColumn__EXPERIMENTAL, $deleteTableRow__EXPERIMENTAL, $getTableNodeFromLexicalNodeOrThrow, $insertTableColumn__EXPERIMENTAL, $insertTableRow__EXPERIMENTAL, $isTableCellNode, getTableSelectionFromTableElement, TableCellHeaderStates, TableCellNode, } from "@lexical/table";
import { $getRoot, $getSelection, $isRangeSelection, DEPRECATED_$getNodeTriplet, DEPRECATED_$isGridSelection, } from "lexical";
import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { ColorPicker } from "../../ui/ColorPicker/ColorPicker";
import { computeSelectionCount } from "./computeSelectionCount";
import { isGridSelectionRectangular } from "./isGridSelectionRectangular";
import { $canUnmerge } from "./$canUnmerge";
import { currentCellBackgroundColor } from "./currentCellBackgroundColor";
import { InsertButton } from "./Button/InsertButton";
import { MergeCellButton } from "./Button/MergeCellButton";
import { mergeTableCellsAtSelectionImpl } from "./functions/mergeTableCellsAtSelectionImpl";
import { unmergeTableCellsAtSelectionImpl } from "./functions/unmergeTableCellsAtSelectionImpl";
import { tableCallbacks } from "./callbacks";
import { tableEffects } from "./effects";
export function TableActionMenu({ onClose, tableCellNode: _tableCellNode, setIsMenuOpen, contextRef, cellMerge, showColorPickerModal, }) {
    const [editor] = useLexicalComposerContext();
    const dropDownRef = useRef(null);
    const [tableCellNode, updateTableCellNode] = useState(_tableCellNode);
    const [selectionCounts, updateSelectionCounts] = useState({
        columns: 1,
        rows: 1,
    });
    const [canMergeCells, setCanMergeCells] = useState(false);
    const [canUnmergeCell, setCanUnmergeCell] = useState(false);
    const [backgroundColor, setBackgroundColor] = useState(() => currentCellBackgroundColor(editor) || "");
    useEffect(() => {
        return editor.registerMutationListener(TableCellNode, (nodeMutations) => {
            const nodeUpdated = nodeMutations.get(tableCellNode.getKey()) === "updated";
            if (nodeUpdated) {
                editor.getEditorState().read(() => {
                    updateTableCellNode(tableCellNode.getLatest());
                });
                setBackgroundColor(currentCellBackgroundColor(editor) || "");
            }
        });
    }, [editor, tableCellNode]);
    useEffect(() => {
        editor.getEditorState().read(() => {
            const selection = $getSelection();
            // Merge cells
            if (DEPRECATED_$isGridSelection(selection)) {
                const currentSelectionCounts = computeSelectionCount(selection);
                updateSelectionCounts(computeSelectionCount(selection));
                setCanMergeCells(isGridSelectionRectangular(selection) &&
                    (currentSelectionCounts.columns > 1 ||
                        currentSelectionCounts.rows > 1));
            }
            // Unmerge cell
            setCanUnmergeCell($canUnmerge());
        });
    }, [editor]);
    useEffect(tableEffects.menuPosition(editor, contextRef, dropDownRef), [
        contextRef,
        dropDownRef,
        editor,
    ]);
    useEffect(() => {
        function handleClickOutside(event) {
            if (dropDownRef.current != null &&
                contextRef.current != null &&
                !dropDownRef.current.contains(event.target) &&
                !contextRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        }
        self.addEventListener("click", handleClickOutside);
        return () => self.removeEventListener("click", handleClickOutside);
    }, [setIsMenuOpen, contextRef]);
    const clearTableSelection = useCallback(() => {
        editor.update(() => {
            if (tableCellNode.isAttached()) {
                const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
                const tableElement = editor.getElementByKey(tableNode.getKey());
                if (!tableElement) {
                    throw new Error("Expected to find tableElement in DOM");
                }
                const tableSelection = getTableSelectionFromTableElement(tableElement);
                if (tableSelection !== null) {
                    tableSelection.clearHighlight();
                }
                tableNode.markDirty();
                updateTableCellNode(tableCellNode.getLatest());
            }
            const rootNode = $getRoot();
            rootNode.selectStart();
        });
    }, [editor, tableCellNode]);
    const mergeTableCellsAtSelection = mergeTableCellsAtSelectionImpl(editor, onClose);
    const unmergeTableCellsAtSelection = unmergeTableCellsAtSelectionImpl(editor);
    const insertTableRowAtSelection = useCallback((shouldInsertAfter) => {
        editor.update(() => {
            $insertTableRow__EXPERIMENTAL(shouldInsertAfter);
            onClose();
        });
    }, [editor, onClose]);
    const insertTableColumnAtSelection = useCallback((shouldInsertAfter) => {
        editor.update(() => {
            for (let i = 0; i < selectionCounts.columns; i++) {
                $insertTableColumn__EXPERIMENTAL(shouldInsertAfter);
            }
            onClose();
        });
    }, [editor, onClose, selectionCounts.columns]);
    const deleteTableRowAtSelection = useCallback(() => {
        editor.update(() => {
            $deleteTableRow__EXPERIMENTAL();
            onClose();
        });
    }, [editor, onClose]);
    const deleteTableAtSelection = useCallback(() => {
        editor.update(() => {
            const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
            tableNode.remove();
            clearTableSelection();
            onClose();
        });
    }, [editor, tableCellNode, clearTableSelection, onClose]);
    const deleteTableColumnAtSelection = useCallback(() => {
        editor.update(() => {
            $deleteTableColumn__EXPERIMENTAL();
            onClose();
        });
    }, [editor, onClose]);
    const toggleTableRowIsHeader = useCallback(tableCallbacks.toggleTableRowIsHeader(editor, tableCellNode, clearTableSelection, onClose), [editor, tableCellNode, clearTableSelection, onClose]);
    const toggleTableColumnIsHeader = useCallback(tableCallbacks.toggleTableColumnIsHeader(editor, tableCellNode, clearTableSelection, onClose), [editor, tableCellNode, clearTableSelection, onClose]);
    const handleCellBackgroundColor = useCallback((value) => {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) ||
                DEPRECATED_$isGridSelection(selection)) {
                const [cell] = DEPRECATED_$getNodeTriplet(selection.anchor);
                if ($isTableCellNode(cell)) {
                    cell.setBackgroundColor(value);
                }
            }
        });
    }, [editor]);
    return createPortal(
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="dropdown" ref={dropDownRef} onClick={(e) => {
            e.stopPropagation();
        }}>
      <MergeCellButton canMergeCells={canMergeCells} canUnmergeCell={canUnmergeCell} cellMerge={cellMerge} mergeTableCellsAtSelection={mergeTableCellsAtSelection} unmergeTableCellsAtSelection={unmergeTableCellsAtSelection}/>
      <button className="item" onClick={() => showColorPickerModal("Cell background color", () => (<ColorPicker color={backgroundColor} onChange={handleCellBackgroundColor}/>))} data-test-id="table-background-color">
        <span className="text">Background color</span>
      </button>
      <hr />
      <InsertButton insertCallback={() => insertTableRowAtSelection(false)} selectionCounts={selectionCounts} side="above" dataTestId="table-insert-row-below" statementOptions={{
            singular: "row",
            plural: "rows",
        }}/>
      <InsertButton insertCallback={() => insertTableRowAtSelection(true)} selectionCounts={selectionCounts} side="below" dataTestId="table-insert-row-below" statementOptions={{
            singular: "row",
            plural: "rows",
        }}/>
      <hr />
      <InsertButton insertCallback={() => insertTableColumnAtSelection(false)} selectionCounts={selectionCounts} side="left" dataTestId="table-insert-column-before" statementOptions={{
            singular: "column",
            plural: "columns",
        }}/>
      <InsertButton insertCallback={() => insertTableColumnAtSelection(true)} selectionCounts={selectionCounts} side="right" dataTestId="table-insert-column-after" statementOptions={{
            singular: "column",
            plural: "columns",
        }}/>
      <hr />
      <button className="item" onClick={() => deleteTableColumnAtSelection()} data-test-id="table-delete-columns">
        <span className="text">Delete column</span>
      </button>
      <button className="item" onClick={() => deleteTableRowAtSelection()} data-test-id="table-delete-rows">
        <span className="text">Delete row</span>
      </button>
      <button className="item" onClick={() => deleteTableAtSelection()} data-test-id="table-delete">
        <span className="text">Delete table</span>
      </button>
      <hr />
      <button className="item" onClick={() => toggleTableRowIsHeader()}>
        <span className="text">
          {(tableCellNode.__headerState & TableCellHeaderStates.ROW) ===
            TableCellHeaderStates.ROW
            ? "Remove"
            : "Add"}
          row header
        </span>
      </button>
      <button className="item" onClick={() => toggleTableColumnIsHeader()}>
        <span className="text">
          {(tableCellNode.__headerState & TableCellHeaderStates.COLUMN) ===
            TableCellHeaderStates.COLUMN
            ? "Remove"
            : "Add"}
          column header
        </span>
      </button>
    </div>, document.querySelector("div.dws-shift-handover-notes"));
}
