export function canDropImage(event) {
    const target = event.target;
    if (!target || !(target instanceof HTMLElement)) {
        return false;
    }
    if (!target.parentElement) {
        return false;
    }
    if (!target.parentElement.closest("div.ContentEditable__root")) {
        return false;
    }
    return true;
}
