import { getDOMSelection } from "./getDOMSelection";
export function getDragSelection(event) {
    const target = event.target;
    const targetWindow = target.nodeType === 9
        ? target === null || target === void 0 ? void 0 : target.defaultView
        : target === null || target === void 0 ? void 0 : target.ownerDocument.defaultView;
    const domSelection = getDOMSelection(targetWindow);
    // caretRangeFromPoint is not supported in Firefox
    if (document.caretRangeFromPoint) {
        return document.caretRangeFromPoint(event.clientX, event.clientY);
    }
    if (event.rangeParent && domSelection !== null) {
        domSelection.collapse(event.rangeParent, event.rangeOffset || 0);
        return domSelection.getRangeAt(0);
    }
    throw Error(`Cannot get the selection when dragging`);
}
