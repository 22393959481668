import { useEffect, useMemo, useRef, useState } from "react";
import { TextInput } from "../TextInput/TextInput";
import { transformColor } from "./transformColor";
import { MoveWrapper } from "./MoveWrapper";
import "./ColorPicker.scss";
const basicColors = [
    "#d0021b",
    "#f5a623",
    "#f8e71c",
    "#8b572a",
    "#7ed321",
    "#417505",
    "#bd10e0",
    "#9013fe",
    "#4a90e2",
    "#50e3c2",
    "#b8e986",
    "#000000",
    "#4a4a4a",
    "#9b9b9b",
    "#ffffff",
];
const WIDTH = 214;
const HEIGHT = 150;
export function ColorPicker({ color, onChange, }) {
    const [selfColor, setSelfColor] = useState(transformColor("hex", color));
    const [inputColor, setInputColor] = useState(color);
    const innerDivRef = useRef(null);
    const saturationPosition = useMemo(() => ({
        x: (selfColor.hsv.s / 100) * WIDTH,
        y: ((100 - selfColor.hsv.v) / 100) * HEIGHT,
    }), [selfColor.hsv.s, selfColor.hsv.v]);
    const huePosition = useMemo(() => ({
        x: (selfColor.hsv.h / 360) * WIDTH,
    }), [selfColor.hsv]);
    const onSetHex = (hex) => {
        setInputColor(hex);
        if (/^#[0-9A-Fa-f]{6}$/i.test(hex)) {
            const newColor = transformColor("hex", hex);
            setSelfColor(newColor);
        }
    };
    const onMoveSaturation = ({ x, y }) => {
        const newHsv = Object.assign(Object.assign({}, selfColor.hsv), { s: (x / WIDTH) * 100, v: 100 - (y / HEIGHT) * 100 });
        const newColor = transformColor("hsv", newHsv);
        setSelfColor(newColor);
        setInputColor(newColor.hex);
    };
    const onMoveHue = ({ x }) => {
        const newHsv = Object.assign(Object.assign({}, selfColor.hsv), { h: (x / WIDTH) * 360 });
        const newColor = transformColor("hsv", newHsv);
        setSelfColor(newColor);
        setInputColor(newColor.hex);
    };
    useEffect(() => {
        // Check if the dropdown is actually active
        if (innerDivRef.current !== null && onChange) {
            onChange(selfColor.hex);
            setInputColor(selfColor.hex);
        }
    }, [selfColor, onChange]);
    useEffect(() => {
        if (color === undefined)
            return;
        const newColor = transformColor("hex", color);
        setSelfColor(newColor);
        setInputColor(newColor.hex);
    }, [color]);
    return (<div className="color-picker-wrapper" style={{ width: WIDTH }} ref={innerDivRef}>
      <TextInput label="Hex" onChange={onSetHex} value={inputColor}/>
      <div className="color-picker-basic-color">
        {basicColors.map((basicColor) => (<button className={basicColor === selfColor.hex ? " active" : ""} key={basicColor} style={{ backgroundColor: basicColor }} onClick={() => {
                setInputColor(basicColor);
                setSelfColor(transformColor("hex", basicColor));
            }}/>))}
      </div>
      <MoveWrapper className="color-picker-saturation" style={{ backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)` }} onChange={onMoveSaturation}>
        <div className="color-picker-saturation_cursor" style={{
            backgroundColor: selfColor.hex,
            left: saturationPosition.x,
            top: saturationPosition.y,
        }}/>
      </MoveWrapper>
      <MoveWrapper className="color-picker-hue" onChange={onMoveHue}>
        <div className="color-picker-hue_cursor" style={{
            backgroundColor: `hsl(${selfColor.hsv.h}, 100%, 50%)`,
            left: huePosition.x,
        }}/>
      </MoveWrapper>
      <div className="color-picker-color" style={{ backgroundColor: selfColor.hex }}/>
    </div>);
}
