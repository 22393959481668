import { INSERT_TABLE_COMMAND } from "@lexical/table";
import { useState, useEffect } from "react";
import { Button } from "../../../../shared/Button/Button";
import { TextInput } from "../../../ui/TextInput/TextInput";
import { DialogActions } from "./Dialog";
import { useTranslation } from "@dws/dws-profile";
export function InsertNewTableDialog({ activeEditor, onClose, }) {
    const { t } = useTranslation();
    const [rows, setRows] = useState("");
    const [columns, setColumns] = useState("");
    const [isDisabled, setIsDisabled] = useState(true);
    useEffect(() => {
        const row = Number(rows);
        const column = Number(columns);
        if (row && row > 0 && row <= 500 && column && column > 0 && column <= 50) {
            setIsDisabled(false);
        }
        else {
            setIsDisabled(true);
        }
    }, [rows, columns]);
    const onClick = () => {
        setRows("0");
        setColumns("0");
        if (isDisabled)
            return;
        activeEditor.dispatchCommand(INSERT_TABLE_COMMAND, { columns, rows });
        onClose();
    };
    return (<>
      <TextInput placeholder={"# of rows (1-500)"} label={t("lexical.rows")} onChange={setRows} value={rows} data-test-id="table-modal-rows" type="number"/>
      <TextInput placeholder={"# of columns (1-50)"} label={t("lexical.columns")} onChange={setColumns} value={columns} data-test-id="table-modal-columns" type="number"/>
      <div className="dialog-footer">
        <DialogActions data-test-id="table-model-confirm-insert">
          <Button onClick={onClick}>{t("lexical.confirm")}</Button>
        </DialogActions>
      </div>
    </>);
}
