import "./Button.scss";
export function Button({ className, onClick, children, "data-testid": testId, error, loading, }) {
    let classNames = ["button", className].join(" ");
    if (error)
        classNames += " error";
    else if (loading)
        classNames += " loading";
    return (<button className={classNames} onClick={onClick} data-testid={testId}>
      {children}
    </button>);
}
