import { $isAtNodeEnd } from "@lexical/selection";
export function _getSelectedNode(selection, isAtNodeEnd) {
    const { anchor, focus } = selection;
    const anchorNode = anchor.getNode();
    const focusNode = focus.getNode();
    if (anchorNode === focusNode)
        return anchorNode;
    const isBackward = selection.isBackward();
    if (isBackward)
        return getNode(focus, anchor, isAtNodeEnd);
    return getNode(anchor, focus, isAtNodeEnd);
}
function getNode(selectedNode, selectedNodeEnd, isAtNodeEndFn) {
    const isAtNodeEnd = isAtNodeEndFn(selectedNode);
    if (isAtNodeEnd)
        return selectedNodeEnd.getNode();
    return selectedNode.getNode();
}
export function getSelectedNode(selection) {
    return _getSelectedNode(selection, $isAtNodeEnd);
}
