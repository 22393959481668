import { useHttpClient } from "../../contexts/httpClientContext/httpClientContext";
const imageCache = new Map();
export function useSuspenseImage(src, { useAuthentication = false }) {
    const httpClient = useHttpClient();
    const parser = (response) => response.blob();
    if (imageCache.has(src)) {
        if (imageCache.get(src) instanceof Promise) {
            throw imageCache.get(src);
        }
        return imageCache.get(src);
    }
    if (useAuthentication) {
        const promise = httpClient.get(src, { parser }).then((response) => {
            const url = URL.createObjectURL(response);
            const img = new Image();
            img.src = url;
            return new Promise((resolve, reject) => {
                img.onload = () => {
                    imageCache.set(src, url);
                    resolve(url);
                };
                img.onerror = reject;
            });
        });
        imageCache.set(src, promise);
        throw promise;
    }
    throw new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            imageCache.set(src, src);
            resolve(null);
        };
    });
}
