export function MergeCellButton({ cellMerge, canMergeCells, canUnmergeCell, mergeTableCellsAtSelection, unmergeTableCellsAtSelection, }) {
    if (!cellMerge)
        return null;
    if (canMergeCells)
        return (<button className="item" onClick={() => mergeTableCellsAtSelection()} data-test-id="table-merge-cells">
        Merge cells
      </button>);
    if (canUnmergeCell) {
        return (<button className="item" onClick={() => unmergeTableCellsAtSelection()} data-test-id="table-unmerge-cells">
        Unmerge cells
      </button>);
    }
}
