export function InsertButton({ insertCallback, selectionCounts, statementOptions, side, dataTestId, }) {
    const { columns } = selectionCounts;
    const { singular, plural } = statementOptions;
    const isDisabled = columns === 0;
    const isSingleColumn = columns === 1;
    const statement = isSingleColumn ? singular : `${columns} ${plural}`;
    return (<button disabled={isDisabled} className="item" onClick={insertCallback} data-test-id={dataTestId}>
      <span className="text">
        Insert {statement} {side}
      </span>
    </button>);
}
