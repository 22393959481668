import { config } from "@dws/dws-auth";
import { useRef } from "react";
import "./Avatar.scss";
import genericPicture from "./generic_profile_picture.jpg";
export function Avatar({ name, profilePicture }) {
    const profilePictureUrl = `${config.IAL_UPLOADS_API}/files/${profilePicture}`;
    function onError() {
        if (imageRef.current && fallbackImageRef.current) {
            imageRef.current.src = fallbackImageRef.current.srcset;
        }
    }
    const imageRef = useRef(null);
    const fallbackImageRef = useRef(null);
    return (<div className="avatar">
      <picture>
        <source ref={fallbackImageRef} srcSet={genericPicture} type="image/webp"/>
        <img data-testId="avatar-img" ref={imageRef} onError={onError} className="avatar-img" src={profilePictureUrl}/>
      </picture>
      <span className="avatar-tooltip">{name}</span>
    </div>);
}
