import { $isElementNode, $isTextNode } from "lexical";
export function $selectLastDescendant(node) {
    const lastDescendant = node.getLastDescendant();
    if ($isTextNode(lastDescendant)) {
        lastDescendant.select();
    }
    else if ($isElementNode(lastDescendant)) {
        lastDescendant.selectEnd();
    }
    else if (lastDescendant !== null) {
        lastDescendant.selectNext();
    }
}
