import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { App } from "./app/app";
import { CONSTANTS } from "./constants";
import { AuthenticatedSPA } from "@dws/dws-auth";
const lifecycles = singleSpaReact({
    React,
    ReactDOM,
    rootComponent: () => (<AuthenticatedSPA clientId={CONSTANTS.AUTH.CLIENT_ID}>
      <App />
    </AuthenticatedSPA>),
    errorBoundary(err, info, props) {
        // Customize the root error boundary for your microfrontend here.
        return null;
    },
});
export const { bootstrap, mount, unmount } = lifecycles;
export default lifecycles;
