import { FORMAT_TEXT_COMMAND } from "lexical";
import { useTranslation } from "@dws/dws-profile";
export function ItalicButton({ activeEditor, isItalic, isEditable, }) {
    const { t } = useTranslation();
    function onClick() {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic");
    }
    return (<button disabled={!isEditable} onClick={onClick} data-testid="italic-button" className={"toolbar-item spaced " + (isItalic ? "active" : "")} title={t("lexical.italic")} type="button" aria-label={`Format text as italics. Shortcut: ${"Ctrl+I"}`}>
      <i className="format italic"/>
    </button>);
}
