import { INSERT_HORIZONTAL_RULE_COMMAND } from "@lexical/react/LexicalHorizontalRuleNode";
import { DropDown } from "../../../ui/DropDown/Dropdown";
import { DropDownItem } from "../../../ui/DropDown/DropDownItem";
import { InsertImageDialog } from "../Dialogs/InsertImageDialog";
import { useModal } from "../../../../../../hooks/layout/modal/useModal";
import { InsertNewTableDialog } from "../Dialogs/InsertTableDialog";
import { useTranslation } from "@dws/dws-profile";
export function SpecialNodeDropdown({ isEditable, activeEditor, }) {
    const { t } = useTranslation();
    const [modal, showModal] = useModal();
    function insertImage() {
        showModal(t("lexical.insert_image"), (onClose) => (<InsertImageDialog activeEditor={activeEditor} onClose={onClose}/>));
    }
    function insertTable() {
        showModal(t("lexical.insert_table"), (onClose) => (<InsertNewTableDialog activeEditor={activeEditor} onClose={onClose}/>));
    }
    function insertHorizontalRule() {
        activeEditor.dispatchCommand(INSERT_HORIZONTAL_RULE_COMMAND, undefined);
    }
    return (<>
      <DropDown disabled={!isEditable} buttonClassName="toolbar-item spaced" buttonLabel={t("lexical.insert")} buttonAriaLabel="Insert specialized editor node" buttonIconClassName="icon plus">
        <DropDownItem onClick={insertHorizontalRule} className="item">
          <i className="icon horizontal-rule"/>
          <span className="text">{t("lexical.horizontal_rule")}</span>
        </DropDownItem>
        <DropDownItem onClick={insertImage} className="item">
          <i className="icon image"/>
          <span className="text">{t("lexical.image")}</span>
        </DropDownItem>
        <DropDownItem onClick={insertTable} className="item">
          <i className="icon table"/>
          <span className="text">{t("lexical.table")}</span>
        </DropDownItem>
      </DropDown>
      {modal}
    </>);
}
