import { FORMAT_TEXT_COMMAND } from "lexical";
import { useTranslation } from "@dws/dws-profile";
export function BoldButton({ activeEditor, isBold, isEditable, }) {
    const { t } = useTranslation();
    function onClick() {
        activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold");
    }
    return (<button disabled={!isEditable} onClick={onClick} className={"toolbar-item spaced " + (isBold ? "active" : "")} data-testid="bold-button" title={t("lexical.bold")} type="button" aria-label={`Format text as bold. Shortcut: ${"Ctrl+B"}`}>
      <i className="format bold"/>
    </button>);
}
