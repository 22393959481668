import { $createCodeNode } from "@lexical/code";
import { INSERT_CHECK_LIST_COMMAND, INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, REMOVE_LIST_COMMAND, } from "@lexical/list";
import { $createHeadingNode, $createQuoteNode, } from "@lexical/rich-text";
import { $setBlocksType } from "@lexical/selection";
import { $createParagraphNode, $getSelection, $isRangeSelection, DEPRECATED_$isGridSelection, } from "lexical";
import { DropDown } from "../../../ui/DropDown/Dropdown";
import { DropDownItem } from "../../../ui/DropDown/DropDownItem";
import { dropDownActiveClass } from "./dropDownActiveClass";
import { useTranslation } from "@dws/dws-profile";
export const blockTypeToBlockName = {
    bullet: "Bulleted List",
    check: "Check List",
    code: "Code Block",
    h1: "Heading 1",
    h2: "Heading 2",
    h3: "Heading 3",
    h4: "Heading 4",
    h5: "Heading 5",
    h6: "Heading 6",
    number: "Numbered List",
    paragraph: "Normal",
    quote: "Quote",
};
export const rootTypeToRootName = {
    root: "Root",
    table: "Table",
};
export function BlockFormatDropDown({ editor, blockType, disabled = false, }) {
    const { t } = useTranslation();
    if (!(blockType in blockTypeToBlockName))
        return null;
    function formatParagraph() {
        editor.update(() => {
            const selection = $getSelection();
            if ($isRangeSelection(selection) ||
                DEPRECATED_$isGridSelection(selection)) {
                $setBlocksType(selection, () => $createParagraphNode());
            }
        });
    }
    function formatHeading(headingSize) {
        if (blockType !== headingSize) {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createHeadingNode(headingSize));
                }
            });
        }
    }
    function formatBulletList() {
        if (blockType !== "bullet") {
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    }
    function formatCheckList() {
        if (blockType !== "check") {
            editor.dispatchCommand(INSERT_CHECK_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    }
    function formatNumberedList() {
        if (blockType !== "number") {
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
        }
        else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
        }
    }
    function formatQuote() {
        if (blockType !== "quote") {
            editor.update(() => {
                const selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    $setBlocksType(selection, () => $createQuoteNode());
                }
            });
        }
    }
    function formatCode() {
        if (blockType !== "code") {
            editor.update(() => {
                let selection = $getSelection();
                if ($isRangeSelection(selection) ||
                    DEPRECATED_$isGridSelection(selection)) {
                    if (selection.isCollapsed()) {
                        $setBlocksType(selection, () => $createCodeNode());
                    }
                    else {
                        const textContent = selection.getTextContent();
                        const codeNode = $createCodeNode();
                        selection.insertNodes([codeNode]);
                        selection = $getSelection();
                        if ($isRangeSelection(selection))
                            selection.insertRawText(textContent);
                    }
                }
            });
        }
    }
    return (<DropDown disabled={disabled} buttonClassName="toolbar-item block-controls" buttonIconClassName={"icon block-type " + blockType} buttonLabel={blockTypeToBlockName[blockType]} buttonAriaLabel={t("lexical.aria_title")}>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "paragraph")} onClick={formatParagraph}>
        <i className="icon paragraph"/>
        <span className="text">{t("lexical.paragraph")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "h1")} onClick={() => formatHeading("h1")}>
        <i className="icon h1"/>
        <span className="text">{t("lexical.h1")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "h2")} onClick={() => formatHeading("h2")}>
        <i className="icon h2"/>
        <span className="text">{t("lexical.h2")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "h3")} onClick={() => formatHeading("h3")}>
        <i className="icon h3"/>
        <span className="text">{t("lexical.h3")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "bullet")} onClick={formatBulletList}>
        <i className="icon bullet-list"/>
        <span className="text">{t("lexical.bullet")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "number")} onClick={formatNumberedList}>
        <i className="icon numbered-list"/>
        <span className="text">{t("lexical.number")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "check")} onClick={formatCheckList}>
        <i className="icon check-list"/>
        <span className="text">{t("lexical.check")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "quote")} onClick={formatQuote}>
        <i className="icon quote"/>
        <span className="text">{t("lexical.quote")}</span>
      </DropDownItem>
      <DropDownItem className={"item " + dropDownActiveClass(blockType === "code")} onClick={formatCode}>
        <i className="icon code"/>
        <span className="text">{t("lexical.code")}</span>
      </DropDownItem>
    </DropDown>);
}
