import { $getTableColumnIndexFromTableCellNode, $getTableNodeFromLexicalNodeOrThrow, $isTableCellNode, $isTableRowNode, TableCellHeaderStates, } from "@lexical/table";
export function toggleTableColumnIsHeaderCallback(editor, tableCellNode, clearTableSelection, onClose) {
    return () => editor.update(() => {
        const tableNode = $getTableNodeFromLexicalNodeOrThrow(tableCellNode);
        const tableColumnIndex = $getTableColumnIndexFromTableCellNode(tableCellNode);
        const tableRows = tableNode.getChildren();
        for (let r = 0; r < tableRows.length; r++) {
            const tableRow = tableRows[r];
            if (!$isTableRowNode(tableRow)) {
                throw new Error("Expected table row");
            }
            const tableCells = tableRow.getChildren();
            if (tableColumnIndex >= tableCells.length || tableColumnIndex < 0) {
                throw new Error("Expected table cell to be inside of table row.");
            }
            const tableCell = tableCells[tableColumnIndex];
            if (!$isTableCellNode(tableCell)) {
                throw new Error("Expected table cell");
            }
            tableCell.toggleHeaderStyle(TableCellHeaderStates.COLUMN);
        }
        clearTableSelection();
        onClose();
    });
}
