import { useRef, useState, useLayoutEffect } from "react";
/**
 * hook to measure the width of the component
 * @param {boolean} eagerObserver - if true, the observer will be called immediately
 * if set to false, the observer will be called on the next animation frame, wich may cause a slight delay and flickering
 * if set to true, the observer will be called immediately, which may cause the observer to throw an error "ResizeObserver loop limit exceeded"
 * @returns {array} - [ref, { height, width }]
 */
export function useMeasure({ eagerObserver = false, }) {
    const ref = useRef(null);
    const [bounds, set] = useState({ height: 0, width: 0 });
    useLayoutEffect(() => {
        const ro = new ResizeObserver(([entry]) => {
            if (eagerObserver) {
                return set(entry.contentRect);
            }
            return self.requestAnimationFrame(() => set(entry.contentRect));
        });
        if (ref.current) {
            ro.observe(ref.current);
        }
        return () => ro === null || ro === void 0 ? void 0 : ro.disconnect();
    }, [ref]);
    return [ref, bounds];
}
