import { FORMAT_TEXT_COMMAND } from "lexical";
import { DropDownItem } from "../../../ui/DropDown/DropDownItem";
import { DropDown } from "../../../ui/DropDown/Dropdown";
import { dropDownActiveClass } from "./dropDownActiveClass";
import { useTranslation } from "@dws/dws-profile";
export function TextStyleDropDown({ activeEditor, isEditable, isStrikethrough, isSubscript, isSuperscript, clearFormatting, }) {
    const { t } = useTranslation();
    return (<DropDown disabled={!isEditable} buttonClassName="toolbar-item spaced" buttonLabel="" buttonAriaLabel="Formatting options for additional text styles" buttonIconClassName="icon dropdown-more">
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough");
        }} className={"item " + dropDownActiveClass(isStrikethrough)} title="Strikethrough" aria-label="Format text with a strikethrough">
        <i className="icon strikethrough"/>
        <span className="text">{t("lexical.strikethrough")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "subscript");
        }} className={"item " + dropDownActiveClass(isSubscript)} title="Subscript" aria-label="Format text with a subscript">
        <i className="icon subscript"/>
        <span className="text">{t("lexical.subscript")}</span>
      </DropDownItem>
      <DropDownItem onClick={() => {
            activeEditor.dispatchCommand(FORMAT_TEXT_COMMAND, "superscript");
        }} className={"item " + dropDownActiveClass(isSuperscript)} title="Superscript" aria-label="Format text with a superscript">
        <i className="icon superscript"/>
        <span className="text">{t("lexical.superscript")}</span>
      </DropDownItem>
      <DropDownItem onClick={clearFormatting} className="item" title="Clear text formatting" aria-label="Clear all text formatting">
        <i className="icon clear"/>
        <span className="text">{t("lexical.clear_formatting")}</span>
      </DropDownItem>
    </DropDown>);
}
