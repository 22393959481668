import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";
import { useCloseModal } from "../../../../hooks/layout/modal/useCloseModal";
import "./Modal.scss";
export function PortalImpl({ onClose, children, title, closeOnClickOutside, }) {
    const modalRef = useRef(null);
    useCloseModal({ modalRef, onClose, closeOnClickOutside });
    useEffect(() => {
        var _a;
        (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, []);
    return (<div className="Modal__overlay" role="dialog">
      <div className="Modal__modal" tabIndex={-1} ref={modalRef}>
        <h2 className="Modal__title">{title}</h2>
        <button className="Modal__closeButton" aria-label="Close modal" type="button" onClick={onClose}>
          X
        </button>
        <div className="Modal__content">{children}</div>
      </div>
    </div>);
}
export function Modal({ onClose, children, title, closeOnClickOutside = false, }) {
    return createPortal(<PortalImpl onClose={onClose} title={title} closeOnClickOutside={closeOnClickOutside}>
      {children}
    </PortalImpl>, document.querySelector("div.dws-shift-handover-notes"));
}
