import { Direction } from "./misc";
export function setStartCursor(direction, editorRootElement, userSelect) {
    const ew = direction === Direction.east || direction === Direction.west;
    const ns = direction === Direction.north || direction === Direction.south;
    const nwse = (direction & Direction.north && direction & Direction.west) ||
        (direction & Direction.south && direction & Direction.east);
    const cursorDir = ew ? "ew" : ns ? "ns" : nwse ? "nwse" : "nesw";
    if (editorRootElement !== null) {
        editorRootElement.style.setProperty("cursor", `${cursorDir}-resize`, "important");
    }
    if (document.body !== null) {
        document.body.style.setProperty("cursor", `${cursorDir}-resize`, "important");
        userSelect.current.value = document.body.style.getPropertyValue("-webkit-user-select");
        userSelect.current.priority = document.body.style.getPropertyPriority("-webkit-user-select");
        document.body.style.setProperty("-webkit-user-select", `none`, "important");
    }
}
