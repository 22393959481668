import { useEffect } from "react";
export function useCloseModal({ modalRef, onClose, closeOnClickOutside, }) {
    useEffect(() => {
        var _a;
        let modalOverlayElement = null;
        const handleEscPress = (event) => {
            if (event.key === "Escape")
                onClose();
        };
        const handleClickOutside = (event) => {
            var _a;
            if (!closeOnClickOutside)
                return;
            if ((_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))
                onClose();
        };
        if ((_a = modalRef === null || modalRef === void 0 ? void 0 : modalRef.current) === null || _a === void 0 ? void 0 : _a.parentElement) {
            modalOverlayElement = modalRef.current.parentElement;
            modalOverlayElement.addEventListener("click", handleClickOutside);
        }
        self.addEventListener("keydown", handleEscPress);
        return () => {
            self.removeEventListener("keydown", handleEscPress);
            modalOverlayElement === null || modalOverlayElement === void 0 ? void 0 : modalOverlayElement.removeEventListener("click", handleClickOutside);
        };
    }, [closeOnClickOutside, onClose, modalRef]);
}
