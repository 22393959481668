import { Children } from "react";
import { useTranslation } from "@dws/dws-profile";
import "./PeopleInvolved.scss";
export function PeopleInvolved({ children, expanded, onClickExpand, showExpandButton, }) {
    const { t } = useTranslation();
    const action = expanded ? "collapse" : "expand";
    const expandButtonClassName = `expand-button ${action}`;
    if (Children.count(children) === 0)
        return null;
    return (<>
      <section className="people-involved">
        <h2 data-testid="people-involved-title">{t("sho.people_involved")}: </h2>
        <div className="avatars">
          {children}
          {!!showExpandButton && (<span className={expandButtonClassName} onClick={onClickExpand} data-testid={action}>
              {action}
            </span>)}
        </div>
      </section>
      <hr />
    </>);
}
