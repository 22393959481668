import { TOGGLE_LINK_COMMAND } from "@lexical/link";
import { COMMAND_PRIORITY_NORMAL, KEY_MODIFIER_COMMAND, } from "lexical";
export function keyModifierEffect(activeEditor) {
    return activeEditor.registerCommand(KEY_MODIFIER_COMMAND, (payload) => {
        const event = payload;
        const { code, ctrlKey, metaKey } = event;
        if (code === "KeyK" && (ctrlKey || metaKey)) {
            event.preventDefault();
            return activeEditor.dispatchCommand(TOGGLE_LINK_COMMAND, "https://");
        }
        return false;
    }, COMMAND_PRIORITY_NORMAL);
}
