var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from "@dws/dws-auth";
export function uploadBase64ImageUsecase(httpClient) {
    return function ({ src, name, type }) {
        return __awaiter(this, void 0, void 0, function* () {
            const url = `${config.IAL_UPLOADS_API}/v2/uploadbase64`;
            const response = yield httpClient.post(url, {
                files: src,
                type,
                name,
            }, {
                headers: {
                    "Content-Type": "application/json",
                },
            }).then((res) => {
                return {
                    type: "success",
                    message: res.title,
                    data: `${config.IAL_UPLOADS_API}/${res.content.str_name}`,
                };
            });
            return response;
        });
    };
}
