var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createCommand } from "lexical";
import { useState } from "react";
import { Button } from "../../../../shared/Button/Button";
import { InsertImageUriDialogBody } from "./InsertImageUriDialogBody";
import { InsertImageUploadedDialogBody } from "./InsertImageUploadedDialogBody";
import { DialogButtonsList } from "./Dialog";
import { useHttpClient } from "../../../../../../contexts/httpClientContext/httpClientContext";
import { uploadBase64ImageUsecase } from "../../../../../../modules/upload/domain/usecases/uploadImageUsecase";
import { useTranslation } from "@dws/dws-profile";
export const INSERT_IMAGE_COMMAND = createCommand("INSERT_IMAGE_COMMAND");
export function InsertImageDialog({ activeEditor, onClose, }) {
    const { t } = useTranslation();
    const [mode, setMode] = useState(null);
    const httpClient = useHttpClient();
    function uploadImage(image) {
        return __awaiter(this, void 0, void 0, function* () {
            const usecase = uploadBase64ImageUsecase(httpClient);
            const result = yield usecase({
                src: image.src,
                name: `${Date.now()}.${image.src.split(";")[0].split("/")[1]}`,
                type: `image/${image.src.split(";")[0].split("/")[1]}`,
            });
            return result;
        });
    }
    // TODO: Adding a image from filesystem should first upload it to the server,
    // then insert the url into the editor.
    const onAddImageFromFilesystem = (payload) => {
        uploadImage(payload).then(({ data }) => {
            const newPayload = Object.assign(Object.assign({}, payload), { src: data });
            activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, newPayload);
            onClose();
        });
    };
    function onAddImageFromURL(payload) {
        activeEditor.dispatchCommand(INSERT_IMAGE_COMMAND, payload);
        onClose();
    }
    if (!mode)
        return (<DialogButtonsList>
        <Button data-test-id="image-modal-option-url" onClick={() => setMode("url")}>
          {t("lexical.url")}
        </Button>
        <Button data-test-id="image-modal-option-file" onClick={() => setMode("file")}>
          {t("lexical.file")}
        </Button>
      </DialogButtonsList>);
    if (mode === "url")
        return <InsertImageUriDialogBody onClick={onAddImageFromURL}/>;
    if (mode === "file")
        return <InsertImageUploadedDialogBody onClick={onAddImageFromFilesystem}/>;
}
