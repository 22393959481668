var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { config } from "@dws/dws-auth";
function fetchApiData(httpClient, params) {
    return __awaiter(this, void 0, void 0, function* () {
        const apiUrl = `${config.IAL_REPORTS_API}/api/v1/shifts/:current-next`;
        return yield httpClient.get(apiUrl, {
            params: {
                LocationId: params.locationId,
                AreaId: params.areaId
            },
            headers: {
                "Content-Type": "application/json",
                "Accept-language": "en-US",
            },
        });
    });
}
export function getCurrentAndNextShiftUsecase(httpClient) {
    return function (params) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const response = yield fetchApiData(httpClient, params);
                const currentShiftWithPosition = Object.assign(Object.assign({}, response.current), { positionIndexFromCurrent: 0 });
                const nextShiftWithPosition = Object.assign(Object.assign({}, response.next), { positionIndexFromCurrent: 1 });
                return {
                    current: currentShiftWithPosition,
                    next: nextShiftWithPosition
                };
            }
            catch (error) {
                console.error(error);
                throw error;
            }
        });
    };
}
