import { mergeRegister } from "@lexical/utils";
import { CAN_REDO_COMMAND, CAN_UNDO_COMMAND, COMMAND_PRIORITY_CRITICAL, } from "lexical";
export function mergeRegisterEffect(editor, activeEditor, $updateToolbar, setIsEditable, setCanUndo, setCanRedo) {
    return () => mergeRegister(editor.registerEditableListener((editable) => {
        setIsEditable(editable);
    }), activeEditor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
            $updateToolbar();
        });
    }), activeEditor.registerCommand(CAN_UNDO_COMMAND, (payload) => {
        setCanUndo(payload);
        return false;
    }, COMMAND_PRIORITY_CRITICAL), activeEditor.registerCommand(CAN_REDO_COMMAND, (payload) => {
        setCanRedo(payload);
        return false;
    }, COMMAND_PRIORITY_CRITICAL));
}
