import { useInformation } from "./hooks/useInformation";
import { SHOPage } from "./presentation/pages/SHOPage/SHOPage";
import { OpenSHOButton } from "./presentation/components/OpenSHOButton/OpenSHOButton";
import { useState, useEffect } from "react";
export function InformationProvider(props) {
    const { isLoading: isInfoLoading, data, error: infoError } = useInformation();
    const [config, setConfig] = useState(null);
    const [configError, setConfigError] = useState(null);
    useEffect(() => {
        if (data) {
            try {
                const builtConfig = buildConfigFromInformation({ data });
                setConfig(builtConfig);
            }
            catch (error) {
                setConfigError(error);
            }
        }
    }, [data]);
    if (isInfoLoading)
        return <OpenSHOButton onClick={() => { }} loading/>;
    if (infoError || configError)
        return <OpenSHOButton onClick={() => { }} error/>;
    const userData = {
        username: props.userData.username,
        accessToken: props.userData.accessToken,
        email: props.userData.email,
        profilePicture: props.userData.profilePicture,
    };
    if (props.shoOpened) {
        if (config) {
            return (<SHOPage userData={userData} onClickClose={() => props.setShoOpened(false)} config={config.config}/>);
        }
        else {
            return <div>Loading Shift configuration...</div>;
        }
    }
    else {
        return <OpenSHOButton onClick={() => props.setShoOpened(true)}/>;
    }
}
export function buildConfigFromInformation({ data, }) {
    const { shiftMetadata, userData, currentAndNextShifts } = data;
    const config = {
        location: shiftMetadata.location,
        area: shiftMetadata.area,
        subarea: shiftMetadata.subarea,
        equipment: shiftMetadata.equipment,
        machine: shiftMetadata.machine,
        shifts: [currentAndNextShifts.current, currentAndNextShifts.next],
    };
    return {
        config,
        userData,
    };
}
