var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export class FetchHTTPClient {
    constructor(baseUrl) {
        this.authenticationHeader = null;
        this.baseUrl = baseUrl;
    }
    setAuthenticationHeader(header) {
        this.authenticationHeader = header;
    }
    get(url, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const params = new URLSearchParams();
            if (options === null || options === void 0 ? void 0 : options.params)
                Object.entries(options.params).forEach(([key, value]) => value && params.append(key, value));
            url = `${this.baseUrl}${url}?${params.toString()}`;
            const headers = new Headers(Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.headers), { Authorization: this.authenticationHeader }));
            const response = yield fetch(url, {
                headers,
            });
            const parser = (_a = options === null || options === void 0 ? void 0 : options.parser) !== null && _a !== void 0 ? _a : ((response) => response.json());
            const parsedResponse = yield parser(response);
            return parsedResponse;
        });
    }
    post(url, data, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = new Headers(Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.headers), { Authorization: this.authenticationHeader }));
            const response = yield fetch(`${this.baseUrl}${url}`, {
                method: "POST",
                headers,
                body: JSON.stringify(data),
            });
            return response.json();
        });
    }
    put(url, data, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = new Headers(Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.headers), { Authorization: this.authenticationHeader }));
            const response = yield fetch(`${this.baseUrl}${url}`, {
                method: "PUT",
                headers,
                body: JSON.stringify(data),
            });
            return response.json();
        });
    }
    delete(url, options) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const headers = new Headers(Object.assign(Object.assign({}, ((_a = options === null || options === void 0 ? void 0 : options.headers) !== null && _a !== void 0 ? _a : {})), { Authorization: this.authenticationHeader }));
            const response = yield fetch(`${this.baseUrl}${url}`, { headers });
            return response.json();
        });
    }
    patch(url, data, options) {
        return __awaiter(this, void 0, void 0, function* () {
            const headers = new Headers(Object.assign(Object.assign({}, options === null || options === void 0 ? void 0 : options.headers), { Authorization: this.authenticationHeader }));
            const response = yield fetch(`${this.baseUrl}${url}`, {
                method: "PATCH",
                headers,
                body: JSON.stringify(data),
            });
            return response.json();
        });
    }
}
