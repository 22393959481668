import { useContext } from "react";
import { ShiftVisualizationContext, } from "../../../contexts/ShiftVisualizationContext/ShiftVisualizationContext";
import { ShiftVisualizationContextProvider } from "../../../contexts/ShiftVisualizationContext/ShiftVisualizationContextProvider";
import { PIDashboardButton } from "../../components/PIDashboardButton/PIDashboardButton";
import { Shift } from "../../components/Shift/Shift";
import { Title } from "../../components/Title/Title";
import { useMeasure } from "../../../hooks/layout/useMeasure";
import { useTranslation } from "@dws/dws-profile";
import "./SHOPage.scss";
export function SHOPage(props) {
    const { t } = useTranslation();
    const [ref, { width }] = useMeasure({ eagerObserver: false });
    const getKey = (str, idx) => self.btoa(`${str}-${idx}`);
    const editorCount = width > 800 ? 2 : 1;
    return (<div className="sho-page">
      <ShiftVisualizationContextProvider initial={props.config}>
        <section className="shift-handover-notes">
          <Title title={t("reports.shift_handover_notes")} onClickClose={props.onClickClose}>
            <PIDashboardButton link={props.config.area.piDashboardLink}/>
          </Title>
          <main ref={ref}>
            {Array.from({ length: editorCount }).map((_, index) => (<ShiftWrapper key={getKey("shift-wrapper", index)} index={index} isSingle={editorCount == 1} userData={props.userData}/>))}
          </main>
        </section>
      </ShiftVisualizationContextProvider>
    </div>);
}
export function ShiftWrapper({ index, isSingle, userData }) {
    const { config } = useContext(ShiftVisualizationContext);
    const { location, area, subarea, equipment, machine } = config;
    const lastUpdate = new Date();
    const shift = config.shifts[index];
    const side = index === 0 ? "left" : "right";
    return (<Shift side={side} location={location} area={area} subarea={subarea} equipment={equipment} machine={machine} shift={shift} isSingle={isSingle} lastUpdate={lastUpdate} userData={userData}/>);
}
