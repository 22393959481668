import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister, $wrapNodeInElement } from "@lexical/utils";
import { $insertNodes, $isRootOrShadowRoot, $createParagraphNode, COMMAND_PRIORITY_EDITOR, DRAGSTART_COMMAND, COMMAND_PRIORITY_HIGH, DRAGOVER_COMMAND, COMMAND_PRIORITY_LOW, DROP_COMMAND, } from "lexical";
import { useEffect } from "react";
import { ImageNode, $createImageNode } from "../../nodes/ImageNode/ImageNode";
import { INSERT_IMAGE_COMMAND, } from "../Toolbar/Dialogs/InsertImageDialog";
import { onDragStart } from "./onDragStart";
import { onDragover } from "./onDragover";
import { onDrop } from "./onDrop";
export function ImagesPlugin({ captionsEnabled, }) {
    const [editor] = useLexicalComposerContext();
    useEffect(() => {
        if (!editor.hasNodes([ImageNode])) {
            throw new Error("ImagesPlugin: ImageNode not registered on editor");
        }
        return mergeRegister(editor.registerCommand(INSERT_IMAGE_COMMAND, (payload) => {
            const imageNode = $createImageNode(payload);
            $insertNodes([imageNode]);
            if ($isRootOrShadowRoot(imageNode.getParentOrThrow())) {
                $wrapNodeInElement(imageNode, $createParagraphNode).selectEnd();
            }
            return true;
        }, COMMAND_PRIORITY_EDITOR), editor.registerCommand(DRAGSTART_COMMAND, (event) => {
            return onDragStart(event);
        }, COMMAND_PRIORITY_HIGH), editor.registerCommand(DRAGOVER_COMMAND, (event) => {
            return onDragover(event);
        }, COMMAND_PRIORITY_LOW), editor.registerCommand(DROP_COMMAND, (event) => {
            return onDrop(event, editor);
        }, COMMAND_PRIORITY_HIGH));
    }, [captionsEnabled, editor]);
    return null;
}
const TRANSPARENT_IMAGE = "data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7";
export const img = document.createElement("img");
img.src = TRANSPARENT_IMAGE;
