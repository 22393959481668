var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { ColorPicker } from "../ColorPicker/ColorPicker";
import { DropDown } from "../DropDown/Dropdown";
export function DropdownColorPicker(_a) {
    var { disabled = false, stopCloseOnClickSelf = true, color, onChange } = _a, rest = __rest(_a, ["disabled", "stopCloseOnClickSelf", "color", "onChange"]);
    return (<DropDown {...rest} disabled={disabled} stopCloseOnClickSelf={stopCloseOnClickSelf}>
      <ColorPicker color={color} onChange={onChange}/>
    </DropDown>);
}
