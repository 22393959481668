import { useTranslation } from "@dws/dws-profile";
import { UNDO_COMMAND } from "lexical";
export function UndoButton({ activeEditor, canUndo, isEditable, }) {
    const { t } = useTranslation();
    function onClick() {
        activeEditor.dispatchCommand(UNDO_COMMAND, undefined);
    }
    return (<button disabled={!canUndo || !isEditable} onClick={onClick} data-testid="undo-button" title={t("lexical.undo")} type="button" className="toolbar-item spaced" aria-label="Undo">
      <i className="format undo"/>
    </button>);
}
