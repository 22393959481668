import { useRef } from "react";
import { clamp } from "./functions/clamp";
export function MoveWrapper({ className, style, onChange, children, }) {
    const divRef = useRef(null);
    const move = (e) => {
        if (divRef.current) {
            const { current: div } = divRef;
            const { width, height, left, top } = div.getBoundingClientRect();
            const x = clamp(e.clientX - left, width, 0);
            const y = clamp(e.clientY - top, height, 0);
            onChange({ x, y });
        }
    };
    const onMouseDown = (e) => {
        if (e.button !== 0)
            return;
        move(e);
        const onMouseMove = (_e) => {
            move(_e);
        };
        const onMouseUp = (_e) => {
            document.removeEventListener("mousemove", onMouseMove, false);
            document.removeEventListener("mouseup", onMouseUp, false);
            move(_e);
        };
        document.addEventListener("mousemove", onMouseMove, false);
        document.addEventListener("mouseup", onMouseUp, false);
    };
    return (<div ref={divRef} className={className} style={style} onMouseDown={onMouseDown}>
      {children}
    </div>);
}
