var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useQuery } from "@tanstack/react-query";
import { useHttpClient } from "../contexts/httpClientContext/httpClientContext";
import { getUserProfilePictureUsecase } from "../modules/upload/domain/usecases/getUserProfilePictureUsecase";
import { useEventBusLastCall } from "./useEventBus";
import { getCurrentAndNextShiftUsecase } from "../usecases/getCurrentAndNextShiftUsecase";
export function useInformation() {
    const httpClient = useHttpClient();
    return useQuery({
        queryKey: ["filters"],
        queryFn: () => __awaiter(this, void 0, void 0, function* () {
            const promises = [getShiftMetadata(httpClient), getUserData(httpClient),];
            const [shiftMetadata, userData] = yield Promise.allSettled(promises);
            const response = {
                shiftMetadata: null,
                userData: null,
                currentAndNextShifts: null,
            };
            if (shiftMetadata.status === "fulfilled") {
                response.shiftMetadata = shiftMetadata.value;
                const locationId = response.shiftMetadata.location.locationId;
                const areaId = response.shiftMetadata.area.areaId;
                const onError = (error) => {
                    console.error("Error fetching current and next shifts:", error);
                    response.currentAndNextShifts = { current: null, next: null };
                };
                try {
                    response.currentAndNextShifts = yield getCurrentAndNextShifts(httpClient, { locationId, areaId }, onError);
                }
                catch (error) {
                    console.log('error', error);
                }
            }
            if (userData.status === "fulfilled") {
                response.userData = userData.value;
            }
            return response;
        }),
    });
}
export function getShiftMetadata(httpClient) {
    const { payload } = useEventBusLastCall("FILTERS_CHANGED");
    return payload;
}
export function getUserData(httpClient) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const usecase = getUserProfilePictureUsecase(httpClient);
            const response = yield usecase();
            return {
                profilePicture: response.data || "",
            };
        }
        catch (error) {
            return {
                profilePicture: "",
            };
        }
    });
}
export function getCurrentAndNextShifts(httpClient, params, onError) {
    return __awaiter(this, void 0, void 0, function* () {
        const usecase = getCurrentAndNextShiftUsecase(httpClient);
        try {
            return yield usecase(params);
        }
        catch (error) {
            onError(error);
        }
    });
}
