import { format } from "date-fns";
import "./Information.scss";
import { useTranslation } from "@dws/dws-profile";
export function Information({ departmentName, areaName, equipmentName, lastUpdate, }) {
    const { t } = useTranslation();
    const formattedLastUpdate = format(lastUpdate, "dd/MM/yyyy");
    return (<div className="information">
      <KeyValue k={t("general.department")} value={departmentName}/>
      <KeyValue k={t("general.area")} value={areaName}/>
      <KeyValue k={t("general.equipment")} value={equipmentName}/>
      <KeyValue k={t("general.last_update")} value={formattedLastUpdate}/>
    </div>);
}
function KeyValue({ k, value }) {
    return (<div className="key-value">
      <span className="key">{k}:</span>
      <span className="value">{value}</span>
    </div>);
}
