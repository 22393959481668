var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useMemo, useReducer, useCallback, useState } from "react";
import { ShiftVisualizationContext, } from "./ShiftVisualizationContext";
import { ShiftVisualizationReducer as reducer } from "./ShiftVisualizationReducer";
import { getNextShiftUsecase } from "../../usecases/getNextShiftUsecase";
import { useHttpClient } from "../httpClientContext/httpClientContext";
import { getPreviousShiftUsecase } from "../../usecases/getPreviousShiftUsecase";
export function ShiftVisualizationContextProvider({ children, initial, }) {
    const [configuration, dispatch] = useReducer(reducer, initial);
    const [isLoading, setIsLoading] = useState(false);
    const httpClient = useHttpClient();
    const changeShifts = useCallback((shifts) => {
        dispatch({
            payload: { shifts },
            type: "NAVIGATE_TO_SHIFT",
        });
    }, []);
    const navigateToNextShift = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const params = {
                locationId: configuration.location.locationId,
                areaId: configuration.area.areaId,
                referenceShiftId: configuration.shifts[1].id,
                referenceShiftStartDateTime: configuration.shifts[1].start,
                referenceShiftPositionFromCurrent: configuration.shifts[1].positionIndexFromCurrent
            };
            const nextShift = yield getNextShiftUsecase(httpClient)(params);
            const [_, newCurrentShift] = configuration.shifts;
            const newShifts = [newCurrentShift, nextShift];
            changeShifts(newShifts);
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            setIsLoading(false);
        }
    }), [configuration.shifts]);
    const navigateToPreviousShift = useCallback(() => __awaiter(this, void 0, void 0, function* () {
        setIsLoading(true);
        try {
            const params = {
                locationId: configuration.location.locationId,
                areaId: configuration.area.areaId,
                referenceShiftId: configuration.shifts[0].id,
                referenceShiftStartDateTime: configuration.shifts[0].start,
                referenceShiftPositionFromCurrent: configuration.shifts[0].positionIndexFromCurrent
            };
            const previousShift = yield getPreviousShiftUsecase(httpClient)(params);
            const [newCurrentShift, _] = configuration.shifts;
            const newShifts = [previousShift, newCurrentShift];
            changeShifts(newShifts);
        }
        catch (error) {
            console.log('error', error);
        }
        finally {
            setIsLoading(false);
        }
    }), [configuration.shifts]);
    function reset() {
        dispatch({ payload: { shifts: initial.shifts }, type: "NAVIGATE_TO_SHIFT" });
    }
    const value = useMemo(() => ({
        config: configuration,
        navigateForward: () => navigateToNextShift(),
        navigateBackward: () => navigateToPreviousShift(),
        reset,
        isLoading,
    }), [configuration, isLoading]);
    return (<ShiftVisualizationContext.Provider value={value}>
      {children}
    </ShiftVisualizationContext.Provider>);
}
