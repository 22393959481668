import { $getSelection, $isRangeSelection, $isRootOrShadowRoot, } from "lexical";
import { $isTableNode } from "@lexical/table";
import { $findMatchingParent, $getNearestNodeOfType } from "@lexical/utils";
import { $isListNode, ListNode } from "@lexical/list";
import { $isLinkNode } from "@lexical/link";
import { $isHeadingNode } from "@lexical/rich-text";
import { getSelectedNode } from "../../../helpers/getSelectedNode";
import { blockTypeToBlockName } from "../Dropdowns/BlockFormatDropDown";
import { $getSelectionStyleValueForProperty, $isParentElementRTL, } from "@lexical/selection";
export function updateToolbarCallback(activeEditor, setIsBold, setIsItalic, setIsUnderline, setIsStrikethrough, setIsSubscript, setIsSuperscript, setIsRTL, setIsLink, setRootType, setBlockType, setFontSize, setFontColor, setBgColor, setFontFamily) {
    return () => {
        const selection = $getSelection();
        if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode();
            let element = anchorNode.getKey() === "root"
                ? anchorNode
                : $findMatchingParent(anchorNode, (e) => {
                    const parent = e.getParent();
                    return parent !== null && $isRootOrShadowRoot(parent);
                });
            if (element === null) {
                element = anchorNode.getTopLevelElementOrThrow();
            }
            const elementKey = element.getKey();
            const elementDOM = activeEditor.getElementByKey(elementKey);
            // Update text format
            setIsBold(selection.hasFormat("bold"));
            setIsItalic(selection.hasFormat("italic"));
            setIsUnderline(selection.hasFormat("underline"));
            setIsStrikethrough(selection.hasFormat("strikethrough"));
            setIsSubscript(selection.hasFormat("subscript"));
            setIsSuperscript(selection.hasFormat("superscript"));
            setIsRTL($isParentElementRTL(selection));
            // Update links
            const node = getSelectedNode(selection);
            const parent = node.getParent();
            if ($isLinkNode(parent) || $isLinkNode(node)) {
                setIsLink(true);
            }
            else {
                setIsLink(false);
            }
            const tableNode = $findMatchingParent(node, $isTableNode);
            if ($isTableNode(tableNode)) {
                setRootType("table");
            }
            else {
                setRootType("root");
            }
            if (elementDOM !== null) {
                if ($isListNode(element)) {
                    const parentList = $getNearestNodeOfType(anchorNode, ListNode);
                    const type = parentList
                        ? parentList.getListType()
                        : element.getListType();
                    setBlockType(type);
                }
                else {
                    const type = $isHeadingNode(element)
                        ? element.getTag()
                        : element.getType();
                    if (type in blockTypeToBlockName) {
                        setBlockType(type);
                    }
                }
            }
            // Handle buttons
            setFontSize($getSelectionStyleValueForProperty(selection, "font-size", "15px"));
            setFontColor($getSelectionStyleValueForProperty(selection, "color", "#000"));
            setBgColor($getSelectionStyleValueForProperty(selection, "background-color", "#fff"));
            setFontFamily($getSelectionStyleValueForProperty(selection, "font-family", "Arial"));
        }
    };
}
