import { useState } from "react";
import { Button } from "../../../../shared/Button/Button";
import { TextInput } from "../../../ui/TextInput/TextInput";
import { DialogActions } from "./Dialog";
import { useTranslation } from "@dws/dws-profile";
export function InsertImageUriDialogBody({ onClick, }) {
    const { t } = useTranslation();
    const [src, setSrc] = useState("");
    const [altText, setAltText] = useState("");
    return (<>
      <TextInput label={t("lexical.image_url")} placeholder="i.e. https://source.unsplash.com/random" onChange={setSrc} value={src} data-test-id="image-modal-url-input"/>
      <TextInput label={t("lexical.alt_text")} placeholder="Random unsplash image" onChange={setAltText} value={altText} data-test-id="image-modal-alt-text-input"/>
      <DialogActions>
        <Button data-test-id="image-modal-confirm-btn" onClick={() => onClick({ altText, src })}>
          {t("lexical.confirm")}
        </Button>
      </DialogActions>
    </>);
}
